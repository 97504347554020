import styled from "styled-components";

// Typesetting

export const H1 = styled.h1`
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 1.4rem;
  font-family: pirulen;
  max-width: 90%;

  @media (min-width: 48em) {
    max-width: 40ch;
    font-size: 2rem;
  }

  @media (min-width: 74em) {
    max-width: 40ch;
    font-size: 2.4rem;
  }
`;

export const H2 = styled.h2`
  font-size: 1.8rem;
  font-family: pirulen;
  max-width: 90%;

  line-height: 1;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1em;
  text-align: center;

  @media (min-width: 48em) {
    font-size: 3rem;
    max-width: 90em;
  } ;
`;
export const H3 = styled.h3`
  font-size: 1.8rem;
  margin-bottom: 1em;
  text-align: center;
  font-family: pirulen;
  max-width: 90%;
`;

export const P = styled.p`
  line-height: 1.6;
  font-size: 1.2rem;
  padding-bottom: 1.2em;
  max-width: 90%;

  @media (min-width: 48em) {
    font-size: 1.8rem;
  }

  @media (min-width: 55rem) {
    max-width: 90ch;
  }
`;
export const HeroP = styled.p`
  line-height: 1.4;
  font-size: 0.8rem;
  padding-bottom: 1em;
  max-width: 90%;

  @media (min-width: 48em) {
    font-size: 1.8rem;
  }

  @media (min-width: 55em) {
    max-width: 65ch;
    font-size: 1.2rem;
  }
`;

export const H1Light = styled.h1`
  display: flex;
  justify-content: center;
  color: white;
  text-align: center;
  font-size: 2.5em;
  font-family: pirulen;
  max-width: 90%;

  @media (min-width: 48em) {
    max-width: 20ch;
    font-size: 3rem;
  }

  @media (min-width: 74em) {
    max-width: 400ch;
    font-size: 3rem;
  }
`;

export const H2Light = styled.h2`
  font-size: 1.8rem;
  font-family: pirulen;
  color: white;
  max-width: 90em;
  line-height: 1;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1em;
  text-align: center;
  max-width: 90%;

  @media (min-width: 48em) {
    font-size: 3rem;
  }
`;
export const H2LightSP = styled.h2`
  font-size: 1.8rem;
  font-family: pirulen;
  color: white;

  line-height: 1;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1em;
  text-align: center;
  max-width: 90%;

  @media (min-width: 330px) {
    font-size: 1rem;
  }

  @media (max-width: 400px) {
    font-size: 1.5rem;
  }

  @media (min-width: 48em) {
    font-size: 3rem;
    max-width: 90em;
  } ;
`;

export const H3Light = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 1em;
  text-align: center;
  color: white;
  font-family: pirulen;
  max-width: 90%;

  @media (min-width: 48em) {
    font-size: 2.5rem;
  }

  @media (min-width: 55rem) {
    font-size: 3rem;
  }
`;

export const PLight = styled.p`
  line-height: 1.6;
  font-size: 0.8rem;
  color: white;
  max-width: 90%;

  @media (min-width: 48em) {
    font-size: 1.3rem;
  }

  @media (min-width: 55rem) {
    font-size: 1.2rem;
  }
`;

// Content

export const Body = styled.body`
  margin: 0;
  box-sizing: border-box;
  background-color: white;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
`;

export const ContentWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
`;

export const HeroBox = styled.div`
  /* margin-top: -3em; */
  width: 100%;
  height: 100%;
  scroll-snap-align: start;
  /* padding-top: 3.4em; */

  background-image: url("Hero_Background.png");

  @media (min-width: 48em) {
  }
`;

export const SectionWrap = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 100%;
  padding: 0 1em;
  text-align: center;
  padding-top: 1em;
  scroll-snap-align: start;
  @media (min-width: 48em) {
  }
  @media (min-width: 56.2em) {
  }

  /* @media (min-width: ) {
    max-width: 90rem;
  } */
`;
export const SectionWrapContact = styled.section`
  background-image: url("Collaboration_Background.png");
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 100%;
  padding: 0 1em;
  text-align: center;
  height: 100%;
  padding-bottom: 4em;
  min-height: 100vh;
  padding-top: 3.5em;
  overflow-x: hidden;

  scroll-snap-align: start;
  @media (min-width: 48em) {
  }
  @media (min-width: 56.2em) {
  }

  /* @media (min-width: ) {
    max-width: 90rem;
  } */
`;
export const SectionWrapTeam = styled.section`
  scroll-snap-align: start;
  scroll-margin: 5em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 100%;
  width: 100%;
  padding-top: 6em;

  text-align: center;
  background: hsla(0, 0%, 100%, 1);

  background: radial-gradient(
    circle,
    hsla(0, 0%, 100%, 1) 0%,
    hsla(202, 65%, 61%, 0.5) 100%
  );

  background: -moz-radial-gradient(
    circle,
    hsla(0, 0%, 100%, 1) 0%,
    hsla(202, 65%, 61%, 0.5) 100%
  );

  background: -webkit-radial-gradient(
    circle,
    hsla(0, 0%, 100%, 1) 0%,
    hsla(202, 65%, 61%, 0.5) 100%
  );

  @media (min-width: 48em) {
  }
  @media (min-width: 56.2em) {
  }

  /* @media (min-width: ) {
    max-width: 90rem;
  } */
`;

export const DarkBGSection = styled.div`
  background-color: #121212;
  margin-top: 15em;
  margin: 0;
  height: 112vh;
  width: 100%;
`;

export const SplitDiv = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 90em;
  height: 100vh;
  margin-bottom: 5em;

  justify-content: center;
  align-items: center;

  @media (min-width: 56.2em) {
    flex-direction: row;
    max-width: 90%;
  }
  > * {
    flex-basis: 100%;
  }
`;

export const RightDiv = styled.div`
  height: 100%;
  width: 100%;
  text-align: flex-start;
  max-height: 50%;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;

  @media (min-width: 48em) {
    width: 100%;
  }

  @media (min-width: 56.2em) {
    max-width: 50%;
    max-height: 100%;
    margin-left: 1.5em;
  }
`;

export const LeftDiv = styled.div`
  height: 100%;
  width: 95%;
  max-height: 50%;
  padding-bottom: 3em;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding-top: 10em;

  @media (min-width: 56.2em) {
    max-width: 50%;
    max-height: 100%;
    margin-right: 1.5em;
  }
`;
export const HeroLeftDiv = styled.div`
  height: 100%;
  width: 95%;
  max-height: 50%;
  padding-bottom: 1.5em;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;

  @media (min-width: 48em) {
    max-width: 50%;
  }

  @media (min-width: 56.2em) {
    max-width: 50%;
    max-height: 100%;
    margin-right: 1.5em;
  }
`;

export const SplitDivIMG = styled.img`
  display: block;
  width: 100%;
  height: auto;
  padding-top: 6em;

  margin: 0 auto;

  @media (min-width: 56.2em) {
    max-width: 95%;
    max-height: 95%;
  } ;
`;

export const SoftwareCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 15rem;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  margin-bottom: 1em;
  margin-right: 1em;
  margin-left: 1em;
`;

export const VideoWrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: auto;
  padding-bottom: 56.25%;

  > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
`;

export const CenteredDiv = styled.div`
  max-width: 90rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow-x: hidden;
  min-height: 100vh;

  @media (min-width: 48rem) {
    max-width: 90%;
  }
`;

export const ContactImage = styled.div`
  background-image: url("Collaboration_Graphic.png");
  background-size: contain;
  overflow-x: hidden;
  background-repeat: no-repeat;
  background-position: center;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 120%;
  margin: 0;

  @media (min-width: 48em) {
    width: 100%;
  }

  @media (min-width: 74rem) {
    height: 90%;
  }
`;

export const CollaborationH2 = styled.h2`
  font-family: Pirulen;
  line-height: 1;
  font-size: 0.9rem;
  padding-bottom: 1em;
  width: 100%;
  max-width: 16rem;

  @media (min-width: 48em) {
    font-size: 2rem;
    max-width: 35rem;
  }

  @media (min-width: 55rem) {
    max-width: 30rem;
  }
`;

export const CollaborationP = styled.h2`
  font-family: roboto;
  font-size: 0.7rem;
  max-width: 16rem;
  text-align: center;

  @media (min-width: 48em) {
    font-size: 1.4rem;
    max-width: 35rem;
  }

  @media (min-width: 55rem) {
    max-width: 30rem;
  }

  @media (min-width: 74rem) {
    max-width: 28rem;
  }
`;

export const CollaborationText = styled.div`
  max-width: 40rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
