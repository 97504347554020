import React from "react";
import { Link as LinkRouter } from "react-router-dom";

import styled from "styled-components";

import { FaAngleRight, FaAngleDoubleRight } from "react-icons/fa";

const Button = styled(LinkRouter)`
  padding: 0.2em 0.6em;
  margin: 1em;
  background-color: white;
  cursor: pointer;
  text-align: center;
  color: black;
  font-size: 1rem;
  transition: 1s;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;

  &:hover {
    transform: scale(105%);
    cursor: pointer;
  }
`;

const ButtonLabel = styled.p`
  font-family: pirulen;
`;

const DefaultArrow = styled.div`
  font-size: 1.5rem;
  padding: 0em 0.2em;
  padding-top: 0.3em;
`;

const CallToActionButtonLight = () => {
  return (
    <>
      <Button to="/experimenter">
        <ButtonLabel>Learn More</ButtonLabel>
        <DefaultArrow>
          <FaAngleRight />
        </DefaultArrow>
      </Button>
    </>
  );
};

export default CallToActionButtonLight;
