import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'

import axios from 'axios'

import styled, { isStyledComponent } from 'styled-components'

import { useForm } from 'react-hook-form'

import { toast } from 'react-toastify'

import { useTable } from 'react-table'

import { useUpload } from 'react-use-upload'

import { getToken, getCurrentUser } from '../../Hooks/useProvideAuth'

// Homepage Nav Components

import Footer from '../../components/Footer/Footer'

import {
  SignUpPageWrapper,
  SignUpFormWrapper,
  Heading,
} from '../Users/UsersElements'

const { REACT_APP_MAIN_API_URL } = process.env

const TableWrapper = styled.div`
  padding: 1rem;
  max-height: 100%;
  overflow-wrap: normal;
  scroll-snap-align: start;

  table {
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`

const UploadButton = styled.input`
  display: none;
`

const UploadButtonLabel = styled.label`
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
  text-decoration: none;

  color: #333;
  background-color: #fff;
  border-color: #ccc;
`

const FileAdmin = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [tableData, setTableData] = useState([])

  let [upload, { progress, done, loading }] = useUpload(({ files }) => {
    let formData = new FormData()
    //files.forEach((file) => formData.append(file.name, file));
    formData.append('file', files[0])

    return {
      method: 'POST',
      url: REACT_APP_MAIN_API_URL + '/downloads/AddDownload',
      body: formData,
      headers: { Authorization: 'Bearer ' + getToken() },
    }
  })

  const cardValues = useRef([])

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  // Handle component unmount
  useEffect(() => {
    // refetch if an upload has just completed
    if (done) {
      retrieveUploadList()
    }

    setIsOpen(isOpen)
    return () => {
      setIsOpen(false)
    }
  }, [done])

  useEffect(() => {
    retrieveUploadList()
  }, [])

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  const dummyData = React.useMemo(
    () => [
      {
        col1: 'Package1',
        col2: 'False',
        col3: 'timestamp-here',
      },
      {
        col1: 'Package2',
        col2: 'False',
        col3: 'timestamp-here',
      },
      {
        col1: 'Package3',
        col2: 'True',
        col3: 'timestamp-here',
      },
    ],
    []
  )

  const retrieveUploadList = () => {
    axios
      .get(REACT_APP_MAIN_API_URL + '/downloads/')
      .then(function (response) {
        var uploadList = response.data
        var mapToColumns = uploadList.map(function (item) {
          return {
            id: item.id,
            FileName: item.file_name,
            CardNumber: item.card_number == null ? '' : item.card_number,
            UploadDate: item.upload_date,
          }
        })

        setTableData(mapToColumns)
      })
      .catch(function (error) {
        toast.error('There was an error retrieving uploads: ' + error, {
          position: 'top-right',
          autoClose: 7000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      })
  }

  const handleActivate = (row) => {
    console.log('Row value' + cardValues.current[row.id].value)
    var new_card_number = Number(cardValues.current[row.id].value)

    axios
      .post(
        REACT_APP_MAIN_API_URL +
          '/downloads/SetDownloadCardNumber/' +
          row.id +
          '/newcard/' +
          new_card_number
      )
      .then(function (response) {
        retrieveUploadList()
      })
      .catch(function (error) {
        toast.error('An error occurred when activating download: ' + error, {
          position: 'top-right',
          autoClose: 7000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      })
  }

  const handleDelete = (row) => {
    axios
      .post(REACT_APP_MAIN_API_URL + '/downloads/RemoveDownload/' + row.id)
      .then(function (response) {
        toast.info(row.FileName + ' deleted successfully!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        })

        retrieveUploadList()
      })
      .catch(function (error) {
        toast.error('An error occurred deleting the row. ' + error, {
          position: 'top-right',
          autoClose: 7000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      })
  }

  // Provide a way to download the uploads
  // TODO: refactor this code (it's duplicated from Download.js)
  const handleDownload = (row) => {
    var code_gen_url =
      REACT_APP_MAIN_API_URL +
      '/downloads/GetDownloadCode/' +
      row.CardNumber +
      '/username/' +
      getCurrentUser()

    axios
      .get(code_gen_url)
      .then(function (response) {
        var download_url =
          REACT_APP_MAIN_API_URL + '/downloads/StartDownload/' + response.data
        const link = document.createElement('a')
        link.href = download_url

        // Append to html link element page
        document.body.appendChild(link)

        // Start download
        link.click()

        // Clean up and remove the link
        link.parentNode.removeChild(link)
      })
      .catch(function (error) {
        toast.error('An error occurred downloading the file: ' + error, {
          position: 'top-right',
          autoClose: 7000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      })
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'File Name',
        accessor: 'FileName', // accessor is the "key" in the data
      },
      {
        id: 'CardNumber',
        Header: 'Card Number',
        accessor: (d) => d.CardNumber.toString(),
      },
      {
        id: 'UploadDate',
        Header: 'Upload Date',
        accessor: (d) => d.UploadDate.toString(),
      },
      {
        width: 300,
        Header: 'Actions',
        Cell: (cell) => (
          <div>
            <label>
              Set Card Number
              <input
                ref={(el) => (cardValues.current[cell.row.original.id] = el)}
              />
            </label>
            <button onClick={() => handleActivate(cell.row.original)}>
              Save
            </button>
            <button onClick={() => handleDelete(cell.row.original)}>
              Delete Download
            </button>
            <button onClick={() => handleDownload(cell.row.original)}>
              Download
            </button>
          </div>
        ),
      },
    ],
    []
  )

  const tableInstance = useTable({ columns, data: tableData })

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance

  return (
    <>
      <SignUpPageWrapper>
        <SignUpFormWrapper>
          <Heading>Package Uploads</Heading>
          <p>
            Note: If a download is not working, try deleting from here and
            re-uploading. It's possible the file was removed and this table is
            out of date.
          </p>
          <br />
          <div>
            {loading ? `${progress}% complete` : null}
            <UploadButtonLabel>
              <UploadButton
                type="file"
                onChange={(e) => {
                  if (e.target.files) {
                    upload({ files: e.target.files })
                  }
                }}
                name="UPLOAD"
              />
              Upload Package
            </UploadButtonLabel>
          </div>
          {/* <button onClick={handleUpload}>Upload new package</button> */}
          <TableWrapper>
            {' '}
            <table {...getTableProps()}>
              <thead>
                {
                  // Loop over the header rows
                  headerGroups.map((headerGroup) => (
                    // Apply the header row props
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {
                        // Loop over the headers in each row
                        headerGroup.headers.map((column) => (
                          // Apply the header cell props
                          <th {...column.getHeaderProps()}>
                            {
                              // Render the header
                              column.render('Header')
                            }
                          </th>
                        ))
                      }
                    </tr>
                  ))
                }
              </thead>
              {/* Apply the table body props */}
              <tbody {...getTableBodyProps()}>
                {
                  // Loop over the table rows
                  rows.map((row) => {
                    // Prepare the row for display
                    prepareRow(row)
                    return (
                      // Apply the row props
                      <tr {...row.getRowProps()}>
                        {
                          // Loop over the rows cells
                          row.cells.map((cell) => {
                            // Apply the cell props
                            return (
                              <td {...cell.getCellProps()}>
                                {
                                  // Render the cell contents
                                  cell.render('Cell')
                                }
                              </td>
                            )
                          })
                        }
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          </TableWrapper>
        </SignUpFormWrapper>
        <Footer />
      </SignUpPageWrapper>
    </>
  )
}

export default FileAdmin
