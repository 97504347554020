// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated. One authenticated, the

import { useLocation, Navigate, Outlet } from 'react-router-dom'
import { useAuth } from '../Hooks/useAuth'

// user will be taken to the original page they were trying to access
const PrivateRoute = function () {
  const auth = useAuth()
  const location = useLocation()

  if (!auth.IsAuthorized()) {
    return <Navigate to="/signin" state={{ from: location }} replace />
  }

  if (!auth.IsVerified()) {
    return <Navigate to="/request-verify" state={{ from: location }} replace />
  }
  return <Outlet />
}

export default PrivateRoute
