import React from "react";

import { Body, H1, P, A, PageContent } from "./ContactUsStyles";

import FooterDownloads from "../Downloads/NavComponents/FooterDownloads";
import Navbar from "../../components/Headers/Navbar";
import MobileNavMenu from "../../components/Headers/MobileNavMenu";
import Footer from "../../components/Footer/Footer";
import BetaFormButton from "../../components/Buttons/BetaFormButton";

const ContactUs = ({ isOpen, toggle }) => {
  return (
    <>
      <Body>
        <Navbar toggle={toggle} />
        <MobileNavMenu isOpen={isOpen} toggle={toggle} />
        <PageContent>
          <H1>Experimenter is now in beta testing</H1>
          <P>
            If you are interested in beta testing, collaborating, or
            contributing to the development of Experimenter please email:
          </P>
          <A href="mailto:info@silicolabs.ca">info@silicolabs.ca</A>
          <BetaFormButton></BetaFormButton>
        </PageContent>
        <Footer />
      </Body>
    </>
  );
};

export default ContactUs;
