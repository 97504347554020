import React, { useState, useEffect } from 'react'

import { useForm } from 'react-hook-form'

import { useNavigate, useLocation } from 'react-router-dom'

import { useAuth } from '../../../Hooks/useAuth'

// Homepage Nav Components

//import NavBarSignin from "./NavElements/NavBarSignin";
//import NavMenu from "./NavElements/NavMenu";

import {
  SignUpPageWrapper,
  SignUpFormWrapper,
  Heading,
  FormContainer,
  InputContainer,
  Required,
  Input,
  ErrorMsg,
  Button,
  GeneralText,
  OrSymbolContainer,
  FancyDivider,
  GoogleSignInButton,
  GoogleIcon,
  ContinueWithGoogle,
  StyledLink,
  ButtonWrap,
} from '../UsersElements'

import Navbar from '../../../components/Headers/Navbar'
import MobileNavMenu from '../../../components/Headers/MobileNavMenu'
import Footer from '../../../components/Footer/Footer'

const SignIn = ({ setToken }) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  // Handle component unmount
  useEffect(() => {
    setIsOpen(isOpen)
    return () => {
      setIsOpen(false)
    }
  }, [])

  const navigate = useNavigate()
  const location = useLocation()
  const auth = useAuth()

  const from = location.state?.from?.pathname || '/'

  let login = (data) => {
    auth.signin(data.username, data.password, from) //.then(() => {
    //   try {
    //     console.log('From was: ', from)
    //     navigate(from, { replace: true })
    //   } catch (error) {
    //     console.log('Error navigating to sign in, error was: ', error)
    //   }
    // })
  }

  return (
    <>
      <Navbar toggle={toggle} />
      <MobileNavMenu isOpen={isOpen} toggle={toggle} />
      <SignUpPageWrapper>
        <SignUpFormWrapper>
          <Heading>Welcome back!</Heading>

          <FormContainer>
            <form onSubmit={handleSubmit(login)}>
              <div></div>
              <div>
                <InputContainer>
                  <label htmlFor="email">
                    Email <Required>*</Required>
                  </label>
                  <Input
                    id="email"
                    type="email"
                    placeholder="Enter your email address"
                    {...register('username', {
                      required: 'Missing Email address.',
                      pattern: {
                        value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                        message: 'Email is not valid.',
                      },
                    })}
                  />
                  {errors.email && (
                    <ErrorMsg className="errorMsg">
                      {errors.email.message}
                    </ErrorMsg>
                  )}
                </InputContainer>
              </div>
              <div>
                <InputContainer>
                  <label htmlFor="password">
                    Password <Required>*</Required>
                  </label>
                  <Input
                    id="password"
                    type="password"
                    placeholder="Enter your password"
                    {...register('password', {
                      required: 'Password is required.',
                      minLength: {
                        value: 6,
                        message: 'Password should be at-least 6 characters.',
                      },
                    })}
                  />
                  {errors.password && (
                    <ErrorMsg className="errorMsg">
                      {errors.password.message}
                    </ErrorMsg>
                  )}
                </InputContainer>
              </div>
              <Button type="submit">Sign In</Button>
            </form>
            {/* <OrSymbolContainer>
              <FancyDivider aria-hidden="true"></FancyDivider>
              <div>Or</div>
              <FancyDivider aria-hidden="true"></FancyDivider>
            </OrSymbolContainer>
            <form>
              <div>
                <GoogleSignInButton>
                  <GoogleIcon
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M7.9 7v2.4H12c-.2 1-1.2 3-4 3-2.4 0-4.3-2-4.3-4.4 0-2.4 2-4.4 4.3-4.4 1.4 0 2.3.6 2.8 1.1l1.9-1.8C11.5 1.7 9.9 1 8 1 4.1 1 1 4.1 1 8s3.1 7 7 7c4 0 6.7-2.8 6.7-6.8 0-.5 0-.8-.1-1.2H7.9z" />
                  </GoogleIcon>
                  <ContinueWithGoogle>Continue with Google</ContinueWithGoogle>
                </GoogleSignInButton>
              </div>
            </form> */}
            <GeneralText>
              Don't have an account yet?{' '}
              <StyledLink to="/signup">Sign Up</StyledLink>
            </GeneralText>
            <br />
            <GeneralText>
              <StyledLink to="/forgot-password">
                I forgot my password
              </StyledLink>
            </GeneralText>
          </FormContainer>
        </SignUpFormWrapper>
        <Footer />
      </SignUpPageWrapper>
    </>
  )
}

export default SignIn
