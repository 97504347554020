import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { NavItems, Logos } from "./NavbarItems";
import { Link as LinkR } from "react-router-dom";
import "./Navbar.css";
import { FaBars } from "react-icons/fa";
import AuthButtons from "../../pages/Users/AuthButtons";
import { render } from "@testing-library/react";

import { CSSTransition } from "react-transition-group";

// Gets the current page name from react router and compares it against the
// NavItems array. It returns only the NavItems with a matching key.

function GetLinksMatchingCurrentPage() {
  const PageName =
    useLocation().pathname.replace("/", "") + "NavItem".toString();

  return NavItems.filter((l) => {
    return l.key.includes(PageName);
  });
}
function GetLogoMatchingCurrentPage() {
  const PageName =
    useLocation().pathname.replace("/", "") + "NavLogo".toString();

  return Logos.filter((l) => {
    return l.key.includes(PageName);
  });
}

function MobileNavMenuButtonContainer(props) {
  return (
    <div className="MobileNavButtonContainer">
      <ul>{props.children}</ul>
    </div>
  );
}

function NavMenuButton(props) {
  const [open, setOpen] = useState(false);

  return (
    <li className="NavMenuButton">
      <a href="#" className="IconButton" onClick={() => setOpen(!open)}>
        {props.icon}
      </a>
      {open && props.children}
    </li>
  );
}

function DropdownMenu() {
  const [activeMenu, setActiveMenu] = useState("main");
  const [menuHeight, setMenuHeight] = useState(null);

  function calcHeight(el) {
    const height = el.offsetHeight;
    setMenuHeight(height);
  }

  function DropDownMenuItem(props) {
    const currentPageLinks = GetLinksMatchingCurrentPage();
    const currentPageLogo = GetLogoMatchingCurrentPage();
    console.log(currentPageLinks);
    return (
      <>
        <a
          href="#"
          className="MenuItem"
          onClick={() => props.goToMenu && setActiveMenu(props.goToMenu)}
        >
          <span className="IconButton">{props.leftIcon}</span>
          {currentPageLinks.map((item, index) => {
            return (
              <>
                <li className="DropdownList" key={index}>
                  <LinkR className={item.cName} id="Mobile" to={item.url}>
                    {item.title}
                  </LinkR>
                </li>
              </>
            );
          })}
          <span className="IconRight">{props.RightIcon}</span>
        </a>
        <AuthButtons />
      </>
    );
  }
  return (
    <div className="Dropdown" style={{ height: menuHeight }}>
      {/* // CSSTransition looks for first childe element ("Menu")
     //When in Prop changes it adds/removes classes. 
     //animation is thus defined in CSS file and occurs when
     //class names are changed */}
      <CSSTransition
        in={activeMenu === "main"}
        unmountOnExit
        timeout={500}
        classNames="MenuPrimary"
        onEnter={calcHeight}
      >
        <div className="Test">
          <div>
            <DropDownMenuItem></DropDownMenuItem>
          </div>
        </div>
      </CSSTransition>
      {/* This allows for nested menu functionality just set gotToMenu in 
      DropdownItem to the activeMenu value of desired sub menu */}
      {/* <CSSTransition
        in={activeMenu === "SubMenu"}
        unmountOnExit
        timeout={500}
        classNames="MenuSecondary"
      >
        <div className="Menu">
          <DropDownMenuItem goToMenu="Main">SubMenu</DropDownMenuItem>
        </div>
      </CSSTransition>{" "} */}
    </div>
  );
}

const Navbar = ({ toggle }, props) => {
  const currentPageLinks = GetLinksMatchingCurrentPage();
  const currentPageLogo = GetLogoMatchingCurrentPage();
  //console.log(currentPageLinks);
  //console.log(currentPageLogo);
  return (
    <>
      <nav className="Navbar">
        {" "}
        <ul className="NavbarLogo">
          {currentPageLogo.map((item, index) => {
            return (
              <li className="LogoContainer" key={index}>
                <LinkR to={item.url}>
                  <img className="nav-logo" src={item.src} alt={item.alt} />
                </LinkR>
              </li>
            );
          })}
        </ul>{" "}
        <ul className="NavbarItems">
          {currentPageLinks.map((item, index) => {
            return (
              <li key={index}>
                <LinkR className={item.cName} to={item.url}>
                  {item.title}
                </LinkR>
              </li>
            );
          })}
        </ul>
        <div className="AuthButtonContainer">
          <AuthButtons />
        </div>
        <div className="MobileButton" onClick={toggle}>
          <FaBars />
        </div>
      </nav>
    </>
  );
};

export default Navbar;
