import React, { useState, useEffect } from 'react'

import { Link, useSearchParams } from 'react-router-dom'

import {
  SignUpPageWrapper,
  SignUpFormWrapper,
  Heading,
  FormContainer,
  InputContainer,
  GenericButton,
  Input,
  ErrorMsg,
  Required,
} from './UsersElements'

import Navbar from '../../components/Headers/Navbar'
import MobileNavMenu from '../../components/Headers/MobileNavMenu'
import Footer from '../../components/Footer/Footer'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

const { REACT_APP_MAIN_API_URL } = process.env

const ForgotPassword = ({ setToken }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isVerified, setIsVerified] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  // Handle component unmount
  let sendResetEmail = (data) => {
    axios
      .post(REACT_APP_MAIN_API_URL + '/auth/forgot-password', {
        email: data.email,
      })
      .then(function (response) {
        toast.success(
          'Password email sent. It may take several minutes to arrive.',
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        )
      })
      .catch(function (error) {
        toast.error(
          'There was an issue sending the password reset email. Please contact support@silicolabs.ca',
          {
            position: 'top-right',
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        )
      })
  }

  return (
    <>
      <Navbar toggle={toggle} />
      <MobileNavMenu isOpen={isOpen} toggle={toggle} />
      <SignUpPageWrapper>
        <SignUpFormWrapper>
          <Heading>Enter your email address</Heading>

          <FormContainer>
            <form onSubmit={handleSubmit(sendResetEmail)}>
              <div></div>
              <div>
                <InputContainer>
                  <label htmlFor="email">
                    Email <Required>*</Required>
                  </label>
                  <Input
                    id="email"
                    type="email"
                    placeholder="Enter your email address"
                    {...register('email', {
                      required: 'Missing Email address.',
                      pattern: {
                        value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                        message: 'Email is not valid.',
                      },
                    })}
                  />
                  {errors.email && (
                    <ErrorMsg className="errorMsg">
                      {errors.email.message}
                    </ErrorMsg>
                  )}
                </InputContainer>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <GenericButton style={{ width: '95%' }} type="submit">
                  Send Password Reset Link
                </GenericButton>
              </div>
            </form>
          </FormContainer>
        </SignUpFormWrapper>
        <Footer />
      </SignUpPageWrapper>
    </>
  )
}

export default ForgotPassword
