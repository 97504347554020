import styled from "styled-components";
import { Link as LinkRouter } from "react-router-dom";
import { Link as LinkScroll } from "react-scroll";

export const FooterContainer = styled.footer`
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding-top: 1em;
  padding-bottom: 1em;

  @media (min-width: 40em) {
    flex-direction: row;
  }
  /* scroll-snap-align: start; */
`;

export const FooterNav = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-basis: 100%;

  max-width: 45%;
  flex-wrap: wrap;

  @media (min-width: 55em) {
    flex-direction: row;
    justify-content: space-evenly;
  }
`;

export const FooterNavSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  max-height: 12rem;
`;

export const FooterNavHeader = styled(LinkRouter)`
  font-size: 1.5rem;
  font-weight: bolder;
  text-decoration: none;
  color: white;
`;

export const FooterNavItem = styled(LinkRouter)`
  display: block;
  font-size: 1.5rem;
  color: white;
  cursor: pointer;
  text-decoration: none;
  line-height: 1.6;

  &:hover {
    text-decoration: underline;
    font-weight: bolder;
    color: powderblue;
  }
`;
export const FooterNavItemCurrentPage = styled(LinkScroll)`
  display: block;
  font-size: 1.5rem;
  color: white;
  cursor: pointer;
  text-decoration: none;
  line-height: 1.6;

  &:hover {
    text-decoration: underline;
    font-weight: bolder;
    color: powderblue;
  }
`;

export const LogoSocialWrap = styled.div`
  flex-basis: 100%;
  max-width: 25rem;
`;

export const FooterSocials = styled.div`
  color: white;
  font-size: 2.2rem;
  margin-top: 0.4em;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
`;

export const LogoImg = styled.img`
  width: 100%;
  max-height: 65%;
  padding-right: 1em;
  padding-left: 1em;

  &:hover {
    cursor: pointer;
  }
`;

export const IconWrap = styled.a`
  transition: 1s;
  &:hover {
    cursor: pointer;
    transform: scale(120%);
  }
`;

export const NavItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const NavItemSection = styled.div`
  padding: 0 2em;
`;

export const FooterContent = styled.div`
  max-width: 90em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;
