import styled from 'styled-components'
import { Link as LinkRouter } from 'react-router-dom'
import { Link as LinkScroll } from 'react-scroll'

export const NavBarWrapper = styled.nav`
  margin: 0;
  box-sizing: border-box;
  width: 100%;
  height: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  z-index: 999;
  @media (min-width: 48em) {
    height: 5em;
  }
`

export const NavBarContents = styled.div`
  width: 100%;
  height: 6em;
  max-width: 150em;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2em;
  @media (min-width: 90em) {
    justify-content: space-evenly;
    padding: 0;
  }
`

export const NavBarLogo = styled(LinkRouter)`
  width: 66%;
  max-width: 20rem;
  height: 7em;
  background-color: transparent;
  display: flex;
  align-items: center;
  transition: 1.5s;

  &:hover {
    transform: scale(110%);
    cursor: pointer;
  }
`

export const LogoImg = styled.img`
  width: 100%;
  height: auto;
`

export const NavButtonsWrap = styled.div`
  display: none;

  @media (min-width: 90em) {
    width: 50%;
    height: 7em;
    background-color: transparent;

    align-self: center;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`

export const NavButton = styled(LinkScroll)`
  padding: 0.2em 0.6em;
  margin: 1em;
  border: 100px solid white;
  background-color: transparent;
  cursor: pointer;
  border: 1px solid white;
  font-size: 1rem;
  text-align: center;
  vertical-align: middle;
  color: white;
  font-family: pirulen;
  transition: 1s;

  &:hover {
    transform: scale(115%);
    background-color: white;
    color: black;
  }
`

export const UserButtonsWrap = styled(LinkRouter)`
  display: none;
  @media (min-width: 90em) {
    height: 7em;
    background-color: transparent;

    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export const UserButton = styled(LinkScroll)`
  padding: 0.2em 0.6em;
  border: 100px solid white;
  background-color: transparent;
  cursor: pointer;
  border: 1px solid white;
  font-size: 1rem;
  text-align: center;
  vertical-align: middle;
  color: white;
  font-family: pirulen;
  text-decoration: none !important;
  transition: color 0.5s, 1s;

  &:hover {
    font-size: 1.1rem;
    transform: translateY(-0.3em);
    background-color: white;
    color: black;
  }
`

export const MobiIconWrap = styled.div`
  display: flex;
  font-size: 2rem;
  justify-content: center;
  align-items: center;
  color: white;
  height: 6rem;
`

export const MobiIcon = styled.div`
  display: block;
  cursor: pointer;
  transition: 2s;

  &:hover {
    transform: scale(150%);
  }

  @media (min-width: 90em) {
    display: none;
  }
`
