import styled from 'styled-components'
import { FaTimes } from 'react-icons/fa'
import { Link as LinkRouter } from 'react-router-dom'
import { Link as LinkScroll } from 'react-scroll'

export const NavMenuContainer = styled.aside`
  position: fixed;
  box-sizing: border-box;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: black;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  transition: 0.3s ease-in-out;
  opacity: ${({ isOpen }) => (isOpen ? '100%' : '0')};
  top: ${({ isOpen }) => (isOpen ? '0' : '-100%')};
`

export const Icon = styled.div`
  height: 7em;
  display: flex;
  cursor: pointer;
  outline: none;
  align-content: flex-end;
  align-self: flex-end;
`

export const CloseIcon = styled(FaTimes)`
  color: #fff;
  margin-top: 0.7em;
  margin-right: 0.6em;
  font-size: 3rem;
  display: block;

  width: 100%;
`

export const LogoWrap = styled(LinkScroll)`
  display: flex;
  margin-top: 1em;
  margin-bottom: 3em;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  text-decoration: none;
  list-style: none;
  transition: 0.2 ease-in-out;
  color: #fff;
  cursor: pointer;
  font-family: pirulen;

  &:hover {
    color: #5baddc;
    transition: 0.2s ease-in-out;
  }
`

export const LogoImg = styled.img`
  width: 80%;
  height: auto;
`

export const NavMenuLinksContainer = styled.ul`
  display: flex;
  flex-direction: column;
`

export const NavMenuLink = styled(LinkRouter)`
  padding: 0.2em 0.6em;
  margin: 0.5em;
  background-color: transparent;
  cursor: pointer;
  border: 1px solid white;
  font-size: 1.2rem;
  text-align: center;
  vertical-align: middle;
  color: white;
  font-family: pirulen;
  transition: 1s;
  text-decoration: none;
  display: flex;
  justify-content: center;

  &:hover {
    transform: scale(110%);
    background-color: white;
    color: black;
  }
`

export const AuthButtonContainer = styled.ul`
  display: flex;
  flex-direction: column;
`

export const SideBtnWrap = styled.div`
  display: flex;
  justify-content: center;
`
