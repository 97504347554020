import React from "react";
import {
  TeamDeckContainer,
  CardContainer,
  TeamCard,
  FounderWrap,
  CardPhoto,
  NameTitle,
  BioInfo,
  CardSocials,
  Name,
  Title,
  Link,
} from "./TeamDeckStyles";

import {
  FaTwitter,
  FaFacebook,
  FaLinkedin,
  FaYoutube,
  FaGithub,
  FaIdCard,
} from "react-icons/fa";

const TeamDeck = () => {
  return (
    <>
      <TeamDeckContainer>
        <CardContainer>
          <FounderWrap>
            <TeamCard>
              <CardPhoto src="KAN.jpg" alt="Kyle Alsbury-Nealy" />
              <img src="" alt="" />
              <NameTitle>
                <Name>Kyle Alsbury-Nealy</Name>
                <Title>Founder, CEO</Title>
              </NameTitle>
              <CardSocials>
                <Link
                  href="https://www.linkedin.com/in/kyle-alsbury-nealy-a1855344/"
                  target="_blank"
                >
                  <FaLinkedin />
                </Link>
                <Link
                  href=" https://github.com/KyleAlsburyNealy"
                  target="_blank"
                >
                  <FaGithub />
                </Link>
                <Link
                  href="https://twitter.com/k_b_nealy?lang=en"
                  target="_blank"
                >
                  <FaTwitter />
                </Link>
              </CardSocials>
            </TeamCard>
          <TeamCard>
            <CardPhoto src="Alsbury_Kyla.jpg" alt="Kyla Alsbury-Nealy" />

            <NameTitle>
              <Name>Kyla Alsbury-Nealy</Name>
              <Title>Admistrative Officer, Business Development</Title>
            </NameTitle>
            <CardSocials>
              <Link
                href="https://www.linkedin.com/in/kyla-alsbury-20726ba4/"
                target="_blank"
              >
                <FaLinkedin />
              </Link>
              <Link
                href="https://twitter.com/kylaalsbury?lang=en"
                target="_blank"
              >
                <FaTwitter />
              </Link>
            </CardSocials>
          </TeamCard>
          </FounderWrap>
        </CardContainer>
      </TeamDeckContainer>
    </>
  );
};

export default TeamDeck;
