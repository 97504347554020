//react imports
import React from "react";
import { animateScroll } from "react-scroll";
import { Link as LinkR } from "react-router-dom";
import { FaTwitter, FaFacebook, FaLinkedin, FaYoutube } from "react-icons/fa";

//element imports
import "./Footer.css";

const Footer = () => {
  const toggleHome = () => {
    animateScroll.scrollToTop();
  };

  return (
    <>
      <footer className="Footer">
        {/* <div className="SplitDiv"> */}
        <div className="FooterLinks">
          <LinkR className="FooterItem" to="/">
            Home
          </LinkR>
          <LinkR className="FooterItem" to="/experimenter">
            Experimenter
          </LinkR>
          <LinkR className="FooterItem" to="/download">
            Downloads
          </LinkR>
          <LinkR className="FooterItem" to="/contactus">
            Contact
          </LinkR>
        </div>
        <div className="FooterLogoSocials">
          <LinkR to="#">
            <img
              className="FooterLogo"
              src="/images/HeaderLogos/SilicoLabs_WithoutLogo.png"
              alt="Stylized font that reads 'SilicoLabs'"
            />
          </LinkR>
          {/* <div className="FooterSocials">
              <a className="FooterSocialLink" href="#" target="_blank">
                <FaFacebook />
              </a>
              <a className="FooterSocialLink" href="#" target="_blank">
                <FaLinkedin />
              </a>
              <a className="FooterSocialLink" href="#" target="_blank">
                <FaTwitter />
              </a>
              <a className="FooterSocialLink" href="#" target="_blank">
                <FaYoutube />
              </a>
            </div> */}
        </div>
        {/* </div> */}
      </footer>
    </>
  );
};

export default Footer;
