// react imports
import React, { useState, useEffect, useContext, createContext } from 'react'
import { Routes, Route } from 'react-router-dom'

import { ToastContainer } from 'react-toastify'
import { toast } from 'react-toastify'

import axios from 'axios'

import qs from 'qs'

// styling import
import './App.css'
import 'react-toastify/dist/ReactToastify.css'

//page imports
import Home from './pages/HomePage/HomePage'
import SignUp from './pages/Users/SignUp/SignUp'
import SignIn from './pages/Users/SignIn/SignIn'
import FileAdmin from './pages/Downloads/FileAdmin'
import Download from './pages/Downloads/Download'
import Experimenter from './pages/ExperimenterPromo/ExperimenterPromo'
import { get } from 'react-scroll/modules/mixins/scroller'
import ScrollToTop from './components/ScrollToTop'
import ContactUs from './pages/ContactUs/ContactUs'
import UserManual from './pages/UserManual/UserManual'
import FeatureOverview from './pages/UserManual/UserManualPages/AboutExperimeneter/FeatureOverview'
import DesignFramework from './pages/UserManual/UserManualPages/AboutExperimeneter/DesignFramework'
import DownloadInstall from './pages/UserManual/UserManualPages/GettingStarted/DownloadInstall'
import ExperimentUI from './pages/UserManual/UserManualPages/GettingStarted/UserInterface'
import Blocks from './pages/UserManual/UserManualPages/ExperimentElements/Blocks'
import Trials from './pages/UserManual/UserManualPages/ExperimentElements/Trials'
import Screens from './pages/UserManual/UserManualPages/ExperimentElements/Screens'
import UserInterface from './pages/UserManual/UserManualPages/GettingStarted/UserInterface'
import Environments from './pages/UserManual/UserManualPages/ExperimentElements/Environments'
import Scenescapes from './pages/UserManual/UserManualPages/ExperimentElements/Scenescapes'
import ResponsiveObjects from './pages/UserManual/UserManualPages/ExperimentElements/ResponsiveObjects'
import InputResponseMaps from './pages/UserManual/UserManualPages/ExperimentElements/InputResponseMaps'
import Properties from './pages/UserManual/UserManualPages/ExperimentElements/Properties'
import VisualPerspective from './pages/UserManual/UserManualPages/ExperimentElements/VisualPerspective'
import MachineLearning from './pages/UserManual/UserManualPages/MachineLearning'
import Session from './pages/UserManual/UserManualPages/ExperimentElements/Sessions'
import Profile from './pages/Profile/Profile'
import Verify from './pages/Users/Verify'
import RequestVerify from './pages/Users/RequestVerify'
import ForgotPassword from './pages/Users/ForgotPassword'
import ResetPassword from './pages/Users/ResetPassword'
import UserManagement from './pages/Admin/UserManagement'
import PrivateRoute from './components/PrivateRoute'
import SuperUserRoute from './components/SuperUserRoute'
import { ProvideAuth } from './Context/AuthProvider'
import { getToken } from './Hooks/useProvideAuth'

// Add a request interceptor
axios.interceptors.request.use(function (config) {
  var jwtAuth = 'Bearer ' + getToken()
  console.log(jwtAuth)
  config.headers.Authorization = jwtAuth

  return config
})

/** For more details on
 * `authContext`, `ProvideAuth`, `useAuth` and `useProvideAuth`
 * refer to: https://usehooks.com/useAuth/
 */

function App() {
  return (
    <ProvideAuth>
      <ScrollToTop />
      <ToastContainer />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/experimenter" element={<Experimenter />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/verify" element={<Verify />} />
        <Route path="/request-verify" element={<RequestVerify />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />

        <Route element={<SuperUserRoute />}>
          <Route path="/user-admin" element={<UserManagement />} />
        </Route>

        <Route element={<PrivateRoute />}>
          <Route path="/profile" element={<Profile />} />
        </Route>

        <Route element={<PrivateRoute />}>
          <Route path="/fileadmin" element={<FileAdmin />} />
        </Route>

        <Route element={<PrivateRoute />}>
          <Route path="/download" element={<Download />} />
        </Route>

        {/* <Route
          path="/usermanual"
          element={<PrivateRoute component={<FeatureOverview />} />}
        />

        <Route
          path="/usermanual/featureoverview"
          element={<PrivateRoute component={<FeatureOverview />} />}
        />

        <Route
          path="/usermanual/designframework"
          element={<PrivateRoute component={<DesignFramework />} />}
        />

        <Route
          path="/usermanual/downloadinstall"
          element={<PrivateRoute component={<DownloadInstall />} />}
        />

        <Route
          path="/usermanual/userinterface"
          element={<PrivateRoute component={<UserInterface />} />}
        />

        <Route
          path="/usermanual/sessions"
          element={<PrivateRoute component={<Session />} />}
        />

        <Route
          path="/usermanual/blocks"
          element={<PrivateRoute component={<Blocks />} />}
        />

        <Route
          path="/usermanual/trials"
          element={<PrivateRoute component={<Trials />} />}
        />

        <Route
          path="/usermanual/screens"
          element={<PrivateRoute component={<Screens />} />}
        />

        <Route
          path="/usermanual/environment"
          element={<PrivateRoute component={<Environments />} />}
        />

        <Route
          path="/usermanual/scenescapes"
          element={<PrivateRoute component={<Scenescapes />} />}
        />

        <Route
          path="/usermanual/responsiveobjects"
          element={<PrivateRoute component={<ResponsiveObjects />} />}
        />

        <Route
          path="/usermanual/properties"
          element={<PrivateRoute component={<Properties />} />}
        />

        <Route
          path="/usermanual/visualperspectives"
          element={<PrivateRoute component={<VisualPerspective />} />}
        />

        <Route
          path="/usermanual/inputresponsemaps"
          element={<PrivateRoute component={<InputResponseMaps />} />}
        />

        <Route
          path="/usermanual/machinelearning"
          element={<PrivateRoute component={<MachineLearning />} />}
        />

        <Route
          path="/fileadmin"
          element={<SuperUserRoute component={<FileAdmin />} />}
        />

        <Route
          path="/download"
          element={<PrivateRoute component={<Download />} />}
        /> */}
      </Routes>
    </ProvideAuth>
  )
}

export default App
