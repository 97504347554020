import React, { useState, useEffect } from 'react'

import Moment from 'moment'

import Navbar from '../../components/Headers/Navbar'
import MobileNavMenu from '../../components/Headers/MobileNavMenu'
import { Heading } from '../Users/UsersElements'
import Footer from '../../components/Footer/Footer'

import styled from 'styled-components'

// @ts-ignore
import FileBrowser, { Icons } from 'react-keyed-file-browser'
import 'font-awesome/css/font-awesome.min.css'
import './Profile.css'
import '../../../node_modules/react-keyed-file-browser/dist/react-keyed-file-browser.css'
import axios from 'axios'
import moment from 'moment'
import { DownloadFile } from '../../Helpers/FileUtil'

const { REACT_APP_MAIN_API_URL } = process.env

export default function Profile() {
  const [isOpen, setIsOpen] = useState(false)
  const [rawFileList, setRawFiles] = useState([])
  const [files, setFiles] = useState([])

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  const getExperiments = () => {
    var filesForBrowser = []

    axios
      .get(REACT_APP_MAIN_API_URL + '/experiment_data/experiment_files')
      .then(function (response) {
        if (response.data !== undefined) {
          var files = response.data
          setRawFiles(files)

          filesForBrowser = files.map((f) => {
            var now = moment()
            var modifiedTime = moment.utc(f.date_added)
            var timeSinceModified = now.diff(modifiedTime)
            var timeSinceDuration = moment.duration(timeSinceModified)

            return {
              key: f.data_file_path,
              // Use this special duration format since that what the library accepts
              modified: +Moment().subtract(timeSinceDuration),
              type: f.data_file_type,
              size: f.file_size,
            }
          })

          setFiles(filesForBrowser)
        }
      })
      .catch(function (response) {
        console.log('Issue getting experiment data: ' + response.error)
      })
  }

  const handleFileDownload = (file) => {
    // lookup the required params from the raw file list, since react keyed only gives us the key which
    // has been truncated for the user
    var fileToDownload = rawFileList.find((f) => f.data_file_path === file[0])

    axios({
      url:
        REACT_APP_MAIN_API_URL +
        '/experiment_data/' +
        fileToDownload.experiment_id +
        '/RunData/' +
        fileToDownload.run_id +
        '/Download/' +
        fileToDownload.data_file_type,
      method: 'GET',
      responseType: 'blob', // important
    })
      .then((response) => {
        DownloadFile(response.data, response.headers)
      })
      .catch(function (response) {
        console.log('Issue getting experiment data file: ' + response)
      })
  }

  const handleFolderDownload = (file) => {
    let requestBody = { downloadPath: file[0] }

    axios({
      url: REACT_APP_MAIN_API_URL + '/experiment_data/zip',
      params: requestBody,
      method: 'GET',
      responseType: 'blob', // important
    })
      .then((response) => {
        DownloadFile(response.data, response.headers)
      })
      .catch(function (response) {
        console.log('Issue getting experiment data zip file: ' + response)
      })
  }

  // Handle component unmount
  useEffect(() => {
    setIsOpen(isOpen)
    getExperiments()

    return () => {
      setIsOpen(false)
    }
  }, [])

  return (
    <>
      <Navbar toggle={toggle} />
      <MobileNavMenu isOpen={isOpen} toggle={toggle} />
      <ProfilePageWrapper>
        <FileContainer>
          <Heading>Experiment Data</Heading>
          <FileBrowserWrapper>
            <FileBrowser
              files={files}
              icons={Icons.FontAwesome(4)}
              detailRenderer={() => <></>}
              onDownloadFile={handleFileDownload}
              onDownloadFolder={handleFolderDownload}
            />
          </FileBrowserWrapper>
        </FileContainer>
        <Footer />
      </ProfilePageWrapper>
    </>
  )
}

export const ProfilePageWrapper = styled.div`
  background-image: url(Background.png);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  @media screen and (max-width: 768px) {
    overflow-y: scroll;
    overflow-x: hidden;
  }

  @media screen and (max-width: 480px) {
    overflow-y: scroll;
    overflow-x: hidden;
  }

  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

export const FileContainer = styled.section`
  color: #ffffff;
  padding: 8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 768px) {
    padding-top: 6rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  @media screen and (max-width: 480px) {
    padding-top: 3.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
`

export const FileBrowserWrapper = styled.div`
  //width: 700px;
  //height: 50vh;
  width: 100%;
`
