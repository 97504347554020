import "./UserManualMobileIndexStyles.css";
import React, { useState } from "react";
import { FaBook, FaAngleRight, FaList } from "react-icons/fa";
import { CSSTransition } from "react-transition-group";
import { IMG } from "../ExperimenterPromo/ExperimenterPromoStyles";

const UserManualMobileIndex = () => {
  return (
    <div className="mobileIndex">
      <div className="mobileIndexTitleIcon">
        <Navbar>
          <NavItem icon={<FaList />}>
            <DropdownMenu />
          </NavItem>
        </Navbar>
      </div>
    </div>
  );
};

function DropdownMenu() {
  const [activeMenu, setActiveMenu] = useState("main");
  const [menuHeight, setMenuHeight] = useState(null);

  function calcHeight(el) {
    const height = Number(el.offsetHeight) * 1.06;
    setMenuHeight(height);
  }

  function DropdownItem(props) {
    return (
      <a
        href="#"
        className="menu-item"
        onClick={() => props.goToMenu && setActiveMenu(props.goToMenu)}
      >
        <span className="icon-button">{props.leftIcon}</span>

        {props.children}
        <span className="icon-right">{props.rightIcon}</span>
      </a>
    );
  }
  return (
    <div className="dropdown" style={{ height: menuHeight }}>
      <CSSTransition
        in={activeMenu === "main"}
        unmountOnExit
        timeout={500}
        classNames="menu-primary"
        onEnter={calcHeight}
      >
        <div className="menu">
          <DropdownItem
            // leftIcon={<FaBook />}
            rightIcon={<FaAngleRight />}
            goToMenu="aboutExperimenter"
          >
            <div className="indexLabel">About Experimenter</div>
          </DropdownItem>
          <DropdownItem
            // leftIcon={<FaBook />}
            rightIcon={<FaAngleRight />}
            goToMenu="gettingStarted"
          >
            <div className="indexLabel">Getting Started</div>
          </DropdownItem>
          <DropdownItem
            // leftIcon={<FaBook />}
            rightIcon={<FaAngleRight />}
            goToMenu="experimentElements"
          >
            <div className="indexLabel">Experiment Elements</div>
          </DropdownItem>
          {/* <DropdownItem
            // leftIcon={
            //   <img
            //     className="icon"
            //     src="/images/user manual index icons/Environment-icon.png"
            //     alt=""
            //   />
            // }
            rightIcon={<FaAngleRight />}
            goToMenu="vrAr"
          >
            <div className="indexLabel">Virtual &#38; Augmented Reality</div>
          </DropdownItem> */}
          <DropdownItem goToMenu="vrAr">
            <a className="indexLabel" href="/usermanual/machinelearning">
              Machine Learning
            </a>
          </DropdownItem>
        </div>
      </CSSTransition>

      <CSSTransition
        in={activeMenu === "aboutExperimenter"}
        unmountOnExit
        timeout={500}
        classNames="menu-secondary"
        onEnter={calcHeight}
      >
        <div className="menu">
          <DropdownItem goToMenu="main">Back</DropdownItem>

          <DropdownItem
          // leftIcon={
          //   <img
          //     className="icon"
          //     src="/images/user manual index icons/Stimulus-icon.png"
          //     alt=""
          //   />
          // }
          >
            <a href="/usermanual" className="indexLabel">
              Feature Overview
            </a>
          </DropdownItem>
          <DropdownItem
          // leftIcon={
          //   <img
          //     className="icon"
          //     src="/images/user manual index icons/Stimulus-icon.png"
          //     alt=""
          //   />
          // }
          >
            <a href="/usermanual/designframework" className="indexLabel">
              Design Framework
            </a>
          </DropdownItem>
        </div>
      </CSSTransition>
      <CSSTransition
        in={activeMenu === "gettingStarted"}
        unmountOnExit
        timeout={500}
        classNames="menu-secondary"
        onEnter={calcHeight}
      >
        <div className="menu">
          <DropdownItem goToMenu="main">Back</DropdownItem>
          <DropdownItem
          // leftIcon={
          //   <img
          //     className="icon"
          //     src="/images/user manual index icons/Stimulus-icon.png"
          //     alt=""
          //   />
          // }
          >
            <a href="/usermanual/downloadinstall" className="indexLabel">
              Download &#38; Install
            </a>
          </DropdownItem>
          <DropdownItem
          // leftIcon={
          //   <img
          //     className="icon"
          //     src="/images/user manual index icons/Stimulus-icon.png"
          //     alt=""
          //   />
          // }
          >
            <a href="/usermanual/userinterface" className="indexLabel">
              User Interface
            </a>
          </DropdownItem>
        </div>
      </CSSTransition>
      <CSSTransition
        in={activeMenu === "experimentElements"}
        unmountOnExit
        timeout={500}
        classNames="menu-secondary"
        onEnter={calcHeight}
      >
        <div className="menu">
          <DropdownItem goToMenu="main">
            <div className="IndexLabel">Back</div>
          </DropdownItem>
          {/* <DropdownItem
            leftIcon={
              <img
                className="icon"
                src="/images/user manual index icons/Experiment-icon.png"
                alt=""
              />
            }
          >
            <a href="/usermanual/experiments" className="indexLabel">
              Experiments
            </a>
          </DropdownItem> */}
          <DropdownItem
            leftIcon={
              <img
                className="icon"
                src="/images/user manual index icons/Session-icon.png"
                alt=""
              />
            }
          >
            <a href="/usermanual/sessions" className="indexLabel">
              Sessions
            </a>
          </DropdownItem>
          <DropdownItem
            leftIcon={
              <img
                className="icon"
                src="/images/user manual index icons/Block-icon.png"
                alt=""
              />
            }
          >
            <a href="/usermanual/blocks" className="indexLabel">
              Blocks
            </a>
          </DropdownItem>
          <DropdownItem
            leftIcon={
              <img
                className="icon"
                src="/images/user manual index icons/Trial-icon.png"
                alt=""
              />
            }
          >
            <a href="/usermanual/trials" className="indexLabel">
              Trials
            </a>
          </DropdownItem>
          <DropdownItem
            leftIcon={
              <img
                className="icon"
                src="/images/user manual index icons/Environment-icon.png"
                alt=""
              />
            }
          >
            <a href="/usermanual/environment" className="indexLabel">
              Environments
            </a>
          </DropdownItem>
          <DropdownItem
            leftIcon={
              <img
                className="icon"
                src="/images/user manual index icons/Screen-icon.png"
                alt=""
              />
            }
          >
            <a href="/usermanual/screens" className="indexLabel">
              Screens
            </a>
          </DropdownItem>
          <DropdownItem
            leftIcon={
              <img
                className="icon"
                src="/images/user manual index icons/Stimulus-icon.png"
                alt=""
              />
            }
          >
            <a href="/usermanual/responsiveobjects" className="indexLabel">
              Responsive Objects
            </a>
          </DropdownItem>
          <DropdownItem
            leftIcon={
              <img
                className="icon"
                src="/images/user manual index icons/DynamicScene-icon.png"
                alt=""
              />
            }
          >
            <a href="/usermanual/scenescapes" className="indexLabel">
              Scenescapes
            </a>
          </DropdownItem>
          <DropdownItem
            leftIcon={
              <img
                className="icon"
                src="/images/user manual index icons/Properties.png"
                alt=""
              />
            }
          >
            <a href="/usermanual/properties" className="indexLabel">
              Properties
            </a>
          </DropdownItem>
          <DropdownItem
            leftIcon={
              <img
                className="icon"
                src="/images/user manual index icons/Perspective-icon.png"
                alt=""
              />
            }
          >
            <a href="/usermanual/visualperspectives" className="indexLabel">
              Visual Perspectives
            </a>
          </DropdownItem>
          <DropdownItem
            leftIcon={
              <img
                className="icon"
                src="/images/user manual index icons/InputResponseMaps.png"
                alt=""
              />
            }
          >
            <a href="/usermanual/inputresponsemaps" className="indexLabel">
              Input-Response maps
            </a>
          </DropdownItem>
        </div>
      </CSSTransition>
      <CSSTransition
        in={activeMenu === "vrAr"}
        unmountOnExit
        timeout={500}
        classNames="menu-secondary"
        onEnter={calcHeight}
      >
        <div className="menu">
          <DropdownItem goToMenu="main">Back</DropdownItem>

          <DropdownItem
          // leftIcon={
          //   <img
          //     className="icon"
          //     src="/images/user manual index icons/Stimulus-icon.png"
          //     alt=""
          //   />
          // }
          >
            <a
              href="/usermanual/immersivevirtualreality"
              className="indexLabel"
            >
              Immersive Virtual Reality
            </a>
          </DropdownItem>
          <DropdownItem
          // leftIcon={
          //   <img
          //     className="icon"
          //     src="/images/user manual index icons/Stimulus-icon.png"
          //     alt=""
          //   />
          // }
          >
            <a href="/usermanual/virtualtestingrooms" className="indexLabel">
              Virtual Testing Rooms
            </a>
          </DropdownItem>
          <DropdownItem
          // leftIcon={
          //   <img
          //     className="icon"
          //     src="/images/user manual index icons/Stimulus-icon.png"
          //     alt=""
          //   />
          // }
          >
            <a href="/usermanual/augmentedreality" className="indexLabel">
              Augmented Reality
            </a>
          </DropdownItem>
        </div>
      </CSSTransition>
    </div>
  );
}

function Navbar(props) {
  return (
    <nav className="navbar">
      <ul className="navbar-nav"> {props.children} </ul>
    </nav>
  );
}

function NavItem(props) {
  const [open, setOpen] = useState(false);

  return (
    <li className="nav-item">
      <a href="#" className="icon-button-sp" onClick={() => setOpen(!open)}>
        {props.icon} <p className="tableOfContents">Table of Contents</p>
      </a>
      {open && props.children}
    </li>
  );
}

export default UserManualMobileIndex;
