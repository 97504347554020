//react imports
import React, { useContext, useState } from "react";
import Experimenter from "../../pages/ExperimenterPromo/ExperimenterPromo";

import {
  Body,
  HeroWrap,
  SectionWrap,
  SectionWrapTeam,
  SplitDiv,
  LeftDiv,
  HeroLeftDiv,
  RightDiv,
  SplitDivIMG,
  H1,
  H2,
  H3,
  P,
  HeroP,
  H1Light,
  H2Light,
  H2LightSP,
  H3Light,
  PLight,
  ContentWrap,
  HeroBox,
  DarkBGSection,
  SoftwareCard,
  VideoWrapper,
  CenteredDiv,
  SectionWrapContact,
  ContactImage,
  CollaborationH2,
  CollaborationP,
  CollaborationText,
} from "./HomePageStyles";

import Footer from "../../components/Footer/Footer";
import TeamDeck from "../../components/TeamDeck/TeamDeck";
import CallToActionButton from "../../components/Buttons/CallToActionButton";
import ContactButton from "../../components/Buttons/ContactButton";
import CallToActionButtonLight from "../../components/Buttons/CallToActionButtonLight";
import Navbar from "../../components/Headers/Navbar";
import { useAuth } from "../../App";
import MobileNavMenu from "../../components/Headers/MobileNavMenu";

const Home = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  console.log("version1");

  return (
    <>
      <Body>
        <Navbar toggle={toggle} />
        <MobileNavMenu isOpen={isOpen} toggle={toggle} />
        {/* <NavBarHome toggle={toggle} />
        <NavMenu isOpen={isOpen} toggle={toggle} /> */}
        <ContentWrap>
          <HeroBox id="herosection">
            <SectionWrap>
              <SplitDiv>
                <HeroLeftDiv>
                  <SplitDivIMG
                    src="Hero_Graphic.png"
                    alt="A stylized line art human head in profile"
                  />
                </HeroLeftDiv>
                <RightDiv>
                  <H1>Cutting-edge research demands cutting-edge tools</H1>
                  <HeroP>
                    Virtual reality, augmented reality, and artificial
                    intelligence are revolutionizing the way we interact with
                    the world around us and provide exciting new ways to study
                    human behaviour. At SilicoLabs, we create tools that enable
                    scientists to leverage these emerging technologies, within a
                    framework designed for interdisciplinary research and
                    collaboration.{" "}
                  </HeroP>

                  <CallToActionButton />
                </RightDiv>
              </SplitDiv>
            </SectionWrap>
          </HeroBox>
          <DarkBGSection>
            <SectionWrap id="elevatorptich">
              <SplitDiv>
                <LeftDiv>
                  <H3Light>Create, Collect, Compare</H3Light>
                  <PLight>
                    SilicoLabs Experimenter software offers a no-code framework
                    that lets researchers design and build 2D/3D experiments and
                    administer them across a wide range of platforms including
                    desktop, mobile, and immersive headsets. Experimenter’s
                    integrated AI tools seamlessly transform experiments built
                    for humans into reinforcement learning environments allowing
                    for the direct comparison between in silico and human data.
                  </PLight>
                  <CallToActionButtonLight />
                </LeftDiv>
                <RightDiv>
                  <SplitDivIMG
                    src="Elevator_Graphic.png"
                    alt="A woman using 100% of her brain"
                  />
                </RightDiv>
              </SplitDiv>
            </SectionWrap>
          </DarkBGSection>

          <DarkBGSection>
            <SectionWrapContact id="contact">
              <ContactImage>
                <CollaborationText>
                  <CollaborationH2>Research and Collaborations</CollaborationH2>
                  <CollaborationP>
                    Our technology is already in use around the world. If you
                    are interested in collaborating with us, please complete the
                    form below to get in touch.
                  </CollaborationP>
                  <ContactButton />
                </CollaborationText>
              </ContactImage>
            </SectionWrapContact>
          </DarkBGSection>
          <SectionWrapTeam id="aboutus">
            <CenteredDiv>
              <H2>About Us</H2>
              <P>
                We believe that technology should enable research, not
                complicate it. Our mission is to provide access to cutting edge
                technology for all researchers. Our team includes practitioners
                in psychology, computer science, rehabilitation, communications,
                and the humanities.
              </P>
              <H2>TEAM</H2>
              <TeamDeck />
            </CenteredDiv>
          </SectionWrapTeam>
        </ContentWrap>
        <Footer />
      </Body>
      {/* <Experimenter /> */}
    </>
  );
};

export default Home;
