import styled from "styled-components";

export const TeamDeckContainer = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;

  /* border: 3px solid black; */
`;

export const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
  max-width: 70em;
  width: 100%;
`;

export const TeamCard = styled.div`
  transition: 1s;
  width: 15rem;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  overflow-x: hidden;
  margin: 1em;
  color: white;
  padding: 0;
  scroll-snap-align: start;
  &:hover {
    transform: scale(102%) translateY(-0.5em);
    background-color: white;
    color: black;
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.7);
  }
`;

export const CardPhoto = styled.img`
  max-width: 100%;
  height: auto;
`;

export const NameTitle = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding-left: 0.5em;
`;

export const CardSocials = styled.div`
  display: flex;
  font-size: 1.5rem;
  padding: 0.5em;
  justify-content: center;
`;

export const BioInfo = styled.div``;

export const Name = styled.h4`
  line-height: 1.6;

  @media (min-width: 74em) {
    font-size: 1.3rem;
    padding: 0 0.5em;
  }
`;

export const Title = styled.h4`
  line-height: 1.3;
  padding: 0 0.5em;
`;

export const FounderWrap = styled.div`
  scroll-snap-align: start;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
`;

export const Link = styled.a`
  color: inherit;
  padding: 0.2em;
  font-size: 1.7rem;
`;
