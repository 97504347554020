import React, { useState } from 'react'
import { Global } from '../../GlobalStyles'
import { useNavigate, useLocation } from 'react-router'

import {
  Body,
  Header,
  Section,
  SplitDiv,
  LeftSplitDiv,
  IMG,
  RightSplitDiv,
  SectionTitle,
  InfoCarousel,
  SlideTitle,
  SlideText,
  HeroIMG,
  HeroSubTitle,
  ExternalLink,
} from './ExperimenterPromoStyles'

import NavBarExperimenter from './NavComponents/NavBarExperimenter'
import NavbarMenuExperimenter from './NavComponents/NavMenuExperimenter'

import DownloadButton from '../../components/Buttons/DownloadButton'
import MobileNavMenu from '../../components/Headers/MobileNavMenu'
import Navbar from '../../components/Headers/Navbar'
import Footer from '../../components/Footer/Footer'
import BetaFormButton from '../../components/Buttons/BetaFormButton'

const Experimenter = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <Global />
      <Body>
        <Header>
          <Navbar toggle={toggle} />
          <MobileNavMenu isOpen={isOpen} toggle={toggle} />
        </Header>
        <Section id="herosection">
          <SplitDiv>
            <RightSplitDiv>
              <HeroIMG src="Experimenter_Text.png" alt="Experimenter" />
              <InfoCarousel>
                <HeroSubTitle>Test Your Hypothesis</HeroSubTitle>
                <SlideText>
                  Experimenter offers a no-code framework that enables
                  researchers to create both 2D and 3D experiments that can be
                  administered using desktop computers, mobile devices, and
                  virtual/augmented reality headsets. Experimenter’s integrated
                  AI modelling tools seamlessly transform experiments built for
                  humans into reinforcement learning environments allowing for
                  the direct comparison between in silico and human data.
                </SlideText>
                <BetaFormButton />
              </InfoCarousel>
            </RightSplitDiv>
          </SplitDiv>
        </Section>
        <Section id="intuitive">
          <SplitDiv>
            <LeftSplitDiv>
              <IMG
                src="Intuitive_Graphic.png"
                alt="Blue line art two hands holding a 3d projection of a city block"
              />
            </LeftSplitDiv>
            <RightSplitDiv>
              <SectionTitle>INTUITIVE</SectionTitle>
              <InfoCarousel>
                <SlideTitle>No-Code Experiment Design</SlideTitle>
                <SlideText>
                  Build fully customizable experiments using Experimenter’s
                  intuitive user interface. Point-and-click to create custom
                  instruction/cue/stimulus-presentation screens or venture into
                  the world of 3D experiment design using drag-and-drop tools to
                  create interactive 3D virtual environments.
                </SlideText>
              </InfoCarousel>
            </RightSplitDiv>
          </SplitDiv>
        </Section>
        <Section id="inventive">
          <SplitDiv>
            <LeftSplitDiv>
              <IMG
                src="Inventive_Graphic.png"
                alt="Blue line art of three gears connected in a collumn"
              />
            </LeftSplitDiv>
            <RightSplitDiv>
              <SectionTitle>INVENTIVE</SectionTitle>
              <InfoCarousel>
                <SlideTitle>Mechanics Built for Creative Design</SlideTitle>
                <SlideText>
                  Experimenter allows researchers to create highly customized
                  experiment objects (e.g., 3D environments and 3D interactive
                  objects) that can be combined in unique ways to create dynamic
                  tasks (e.g., populating a 3D environment with different
                  combinations of interactive objects). This modular framework
                  results in emergent functionality that provides extraordinary
                  task design flexibility.
                </SlideText>
              </InfoCarousel>
            </RightSplitDiv>
          </SplitDiv>
        </Section>
        <Section id="BCI">
          <SplitDiv>
            <LeftSplitDiv>
              {' '}
              <IMG
                src="BCI_Graphic.png"
                alt="Stylized line art of a humna head with a gear inside"
              />
            </LeftSplitDiv>
            <RightSplitDiv>
              <SectionTitle>Integrated</SectionTitle>
              <InfoCarousel>
                <SlideTitle>BrainFlow Biosensor Data Tools</SlideTitle>
                <SlideText>
                  Experimenter provides easy-to-use tools for synchronizing and
                  utilizing Biosensor data through our BrainFlow integration.
                  BrainFlow is an open source toolbox which permits the
                  collection, parsing, and analysis of data from biosensors,
                  including electroencephalogram (EEG), electromyography (EMG),
                  and electrocardiogram (ECG). For more information visit{' '}
                  <ExternalLink href="https://BrainFlow.org">
                    BrainFlow.org
                  </ExternalLink>
                  .
                </SlideText>
              </InfoCarousel>
            </RightSplitDiv>
          </SplitDiv>
        </Section>
        <Section id="immersive">
          <SplitDiv>
            <LeftSplitDiv>
              <IMG
                src="Immersive_Graphic.png"
                alt="A non descript human wearing a virtual reality headset"
              />
            </LeftSplitDiv>
            <RightSplitDiv>
              <SectionTitle>IMMERSIVE</SectionTitle>
              <InfoCarousel>
                <SlideTitle>
                  Plug-and-Play Virtual &#38; Augmented Reality (XR)
                </SlideTitle>
                <SlideText>
                  Creating XR experiments has never been easier. Simply plug in
                  an XR headset and your 2D or 3D experiment is be transformed
                  into an immersive experience. Experimenter’s Virtual Testing
                  Rooms allow any experiment to be exported to standalone XR
                  headsets (e.g., Oculus Quest 2, Hololens 2) making it possible
                  to run experiments outside the lab; a feature that can be used
                  to reach new participant populations.
                </SlideText>
              </InfoCarousel>
            </RightSplitDiv>
          </SplitDiv>
        </Section>
        <Section id="intelligent">
          <SplitDiv>
            <LeftSplitDiv>
              {' '}
              <IMG
                src="Intelligent_Graphic.png"
                alt="Stylized line art of a humna head with a gear inside"
              />
            </LeftSplitDiv>
            <RightSplitDiv>
              <SectionTitle>INTELLIGENT</SectionTitle>
              <InfoCarousel>
                <SlideTitle>
                  Integrated State of the Art Modelling Tools
                </SlideTitle>
                <SlideText>
                  For researchers interested in modeling human
                  behaviour/cognition, Experimenter allows tasks built for
                  humans to be easily transformed into reinforcement learning
                  environments with action spaces analogous to input devices
                  (keyboard, gamepad, etc.). This allows for the direct
                  comparison between in silico and human data.
                </SlideText>
              </InfoCarousel>
            </RightSplitDiv>
          </SplitDiv>
        </Section>

        <Footer />
      </Body>
    </>
  )
}

export default Experimenter
