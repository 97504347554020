import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import axios from 'axios'

import styled, { isStyledComponent } from 'styled-components'

import { toast } from 'react-toastify'

import { useNavigate, useLocation } from 'react-router-dom'

import { getCurrentUser } from '../../Hooks/useProvideAuth'

// Homepage Nav Components

import {
  DownloadBody,
  Title,
  Section,
  Card,
  SectionTitle,
  CardContainer,
  SectionContents,
  CardTitle,
  DownloadCardImage,
} from '../Users/UsersElements'

import Navbar from '../../components/Headers/Navbar'
import MobileNavMenu from '../../components/Headers/MobileNavMenu'
import Footer from '../../components/Footer/Footer'

const { REACT_APP_MAIN_API_URL } = process.env

const Download = () => {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    setIsOpen(isOpen)
    return () => {
      setIsOpen(false)
    }
  }, [])

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  const InitiateDownload = (card_number) => () => {
    var code_gen_url =
      REACT_APP_MAIN_API_URL +
      '/downloads/GetDownloadCode/' +
      card_number +
      '/username/' +
      getCurrentUser()

    axios
      .get(code_gen_url)
      .then(function (response) {
        var download_url =
          REACT_APP_MAIN_API_URL + '/downloads/StartDownload/' + response.data
        const link = document.createElement('a')
        link.href = download_url

        // Append to html link element page
        document.body.appendChild(link)

        // Start download
        link.click()

        // Clean up and remove the link
        link.parentNode.removeChild(link)
      })
      .catch(function (error) {
        toast.error(
          'Something went wrong with your download. For assistance please contact info@silicolabs.ca',
          {
            position: 'top-right',
            autoClose: 7000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        )
      })
  }

  let history = useNavigate()

  return (
    <>
      <Navbar toggle={toggle} />
      <MobileNavMenu isOpen={isOpen} toggle={toggle} />
      <DownloadBody>
        <Title>Downloads</Title>
        <Section>
          <SectionTitle>Experimenter Package</SectionTitle>
          <SectionContents>
            <CardContainer>
              <Card onClick={InitiateDownload(1)}>
                {' '}
                <DownloadCardImage
                  src="\images\DownloadCards\Experimenter.png"
                  alt=""
                />{' '}
              </Card>
              <CardTitle>Experimenter Package</CardTitle>
            </CardContainer>
          </SectionContents>
        </Section>
        <Section>
          <SectionTitle>Image Packages</SectionTitle>
          <SectionContents>
            <CardContainer>
              <Card onClick={InitiateDownload(2)}>
                <DownloadCardImage
                  src="\images\DownloadCards\ImageFiles.png"
                  alt=""
                />
              </Card>
              <CardTitle>BOSS A to F</CardTitle>
            </CardContainer>
            <CardContainer>
              <Card onClick={InitiateDownload(3)}>
                <DownloadCardImage
                  src="\images\DownloadCards\ImageFiles.png"
                  alt=""
                />
              </Card>
              <CardTitle>BOSS G to F</CardTitle>
            </CardContainer>
            <CardContainer>
              <Card onClick={InitiateDownload(4)}>
                <DownloadCardImage
                  src="\images\DownloadCards\ImageFiles.png"
                  alt=""
                />
              </Card>
              <CardTitle>Multi Picture Unity Pack</CardTitle>
            </CardContainer>
            <CardContainer>
              <Card onClick={InitiateDownload(5)}>
                <DownloadCardImage
                  src="\images\DownloadCards\ImageFiles.png"
                  alt=""
                />
              </Card>
              <CardTitle>UI Unity Pack</CardTitle>
            </CardContainer>
          </SectionContents>
        </Section>
        <Section>
          <SectionTitle>3D Model Packages</SectionTitle>
          <SectionContents>
            <CardContainer>
              <Card onClick={InitiateDownload(6)}>
                <DownloadCardImage
                  src="\images\DownloadCards\3DModels.png"
                  alt=""
                />
              </Card>
              <CardTitle>Buildings Pack</CardTitle>
            </CardContainer>
            <CardContainer>
              <Card onClick={InitiateDownload(7)}>
                <DownloadCardImage
                  src="\images\DownloadCards\3DModels.png"
                  alt=""
                />
              </Card>
              <CardTitle>Characters &#38; Animals Pack</CardTitle>
            </CardContainer>
            <CardContainer>
              <Card onClick={InitiateDownload(8)}>
                <DownloadCardImage
                  src="\images\DownloadCards\3DModels.png"
                  alt=""
                />
              </Card>
              <CardTitle>Food Pack</CardTitle>
            </CardContainer>
            <CardContainer>
              <Card onClick={InitiateDownload(9)}>
                <DownloadCardImage
                  src="\images\DownloadCards\3DModels.png"
                  alt=""
                />
              </Card>
              <CardTitle>Interior Environments Pack</CardTitle>
            </CardContainer>
            <CardContainer>
              <Card onClick={InitiateDownload(10)}>
                <DownloadCardImage
                  src="\images\DownloadCards\3DModels.png"
                  alt=""
                />
              </Card>
              <CardTitle>Modular Roads Pack</CardTitle>
            </CardContainer>
            <CardContainer>
              <Card onClick={InitiateDownload(11)}>
                <DownloadCardImage
                  src="\images\DownloadCards\3DModels.png"
                  alt=""
                />
              </Card>
              <CardTitle>Nature Pack</CardTitle>
            </CardContainer>
            <CardContainer>
              <Card onClick={InitiateDownload(12)}>
                <DownloadCardImage
                  src="\images\DownloadCards\3DModels.png"
                  alt=""
                />
              </Card>
              <CardTitle>Outdoor Environments Pack</CardTitle>
            </CardContainer>
            <CardContainer>
              <Card onClick={InitiateDownload(13)}>
                <DownloadCardImage
                  src="\images\DownloadCards\3DModels.png"
                  alt=""
                />
              </Card>
              <CardTitle>Roads Pack</CardTitle>
            </CardContainer>
            <CardContainer>
              <Card onClick={InitiateDownload(14)}>
                <DownloadCardImage
                  src="\images\DownloadCards\3DModels.png"
                  alt=""
                />
              </Card>
              <CardTitle>Testing Rooms Pack</CardTitle>
            </CardContainer>
            <CardContainer>
              <Card onClick={InitiateDownload(15)}>
                <DownloadCardImage
                  src="\images\DownloadCards\3DModels.png"
                  alt=""
                />
              </Card>
              <CardTitle>Vehicles Pack</CardTitle>
            </CardContainer>
            <CardContainer>
              <Card onClick={InitiateDownload(16)}>
                <DownloadCardImage
                  src="\images\DownloadCards\3DModels.png"
                  alt=""
                />
              </Card>
              <CardTitle>Weapons, Survival, Fantasy Objects Pack</CardTitle>
            </CardContainer>
          </SectionContents>
        </Section>
        <Section>
          <SectionTitle>Audio File Packages</SectionTitle>
          <SectionContents>
            <CardContainer>
              <Card onClick={InitiateDownload(4)}>
                <DownloadCardImage
                  src="\images\DownloadCards\AudioFiles.png"
                  alt=""
                />
              </Card>
              <CardTitle>All Audio Pack</CardTitle>
            </CardContainer>
          </SectionContents>
        </Section>
      </DownloadBody>
      <Footer />
    </>
  )
}

export default Download

{
  /* <SignUpPageWrapper>
  <NavMenuDownloads isOpen={isOpen} toggle={toggle} />
  <NavBarDownloads toggle={toggle} useSolid={true} />
  <SignUpFormWrapper>
    <Heading>
      Thank you for your interest in Experimenter. Please Enter your download
      code below
    </Heading>
    <FormContainer>
      <form onSubmit={handleSubmit(InitiateDownload)}>
        {/* <div>
            <InputContainer>
              <label htmlFor="download_code">
                Download Code<Required>*</Required>
              </label>
              <Input
                id="download_code"
                type="text"
                placeholder="Enter your download code"
                name="download_code"
                {...register("download_code", {
                  required: "A download code is required.",
                })}
              />
              {errors.download_code && (
                <ErrorMsg className="errorMsg">
                  {errors.download_code.message}
                </ErrorMsg>
              )}
            </InputContainer>
          </div>
          <div>
            <Button type="submit">Start Download</Button>
          </div> */
}
//       </form>
//     </FormContainer>
//   </SignUpFormWrapper>
//   <FooterDownloads />
// </SignUpPageWrapper>; */}
