import { useAuth } from '../../Hooks/useAuth'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { Link as LinkRouter } from 'react-router-dom'

const AuthButtons = () => {
  let history = useNavigate()
  let auth = useAuth()

  const AuthButtonWrap = styled.div`
    display: flex;
    flex-direction: row;

    @media (min-width: 74em) {
      flex-direction: row;
      margin-top: 0;
    }
  `

  const NavSignIn = styled(LinkRouter)`
    display: flex;
    font-size: 0.8rem;
    margin-left: 1em;
    margin-right: 1em;
    padding: 0.4em 0.8em 0.4em 0.8em;
    text-decoration: none;
    color: white;
    font-family: pirulen;
    border: 1px solid white;
    transition: 0.6s;
    text-align: center;

    &:hover {
      transform: scale(115%);
      background-color: white;
      color: black;
      text-decoration: none !important;
    }

    @media (min-width: 74em) {
      font-size: 0.8em;
    }
  `

  const AuthButtonLabel = styled.p`
    text-decoration: none;
    font-family: inherit;
    text-decoration-color: transparent;
  `

  return auth.IsAuthorized() == true ? (
    <p>
      <NavSignIn
        onClick={() => {
          auth.signout(() => history.push('/'))
        }}
      >
        Sign out
      </NavSignIn>
    </p>
  ) : (
    <>
      <AuthButtonWrap>
        <NavSignIn to="/signin" style={{ textDecoration: 'none !important' }}>
          <AuthButtonLabel>Sign in</AuthButtonLabel>
        </NavSignIn>
        <NavSignIn to="/signup">Sign up</NavSignIn>
      </AuthButtonWrap>
    </>
  )
}

export default AuthButtons
