import styled from "styled-components";

export const Body = styled.body`
  box-sizing: border-box;
  margin: 0;
  width: 100%;
  font-weight: 400;
  font-size: 1.3125rem;
  line-height: 1.6;
  background-image: url(Background.png);
  background-color: #121212;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  border: none;
  flex-direction: column;
`;

export const Header = styled.header`
  margin: 0;
  width: 100%;
`;

export const Section = styled.section`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  /* border: 4px solid red; */
  scroll-snap-align: start;
`;

export const SplitDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-basis: 100%;
  width: 100%;
  height: 100%;
  padding-top: 4em;
  /* border: 13px solid green; */
  max-height: 98%;

  @media (min-width: 56em) {
    flex-direction: row;
  }

  @media (orientation: landscape) {
    flex-direction: row;
  }
`;

export const LeftSplitDiv = styled.div`
  width: 100%;
  height: 90%;
  /* border: 4px solid yellow; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-basis: 100%;
  max-height: 50%;

  @media (min-width: 56em) {
    max-height: 100%;
  }

  @media (min-width: 47.5em) {
    max-height: 48%;
    margin: 0;
  }
`;

export const IMG = styled.img`
  height: 100%;
  width: auto;

  @media (min-width: 56em) {
    width: 100%;
    height: auto;
  }
  @media (min-width: 68em) {
    width: 79%;
    height: auto;
  }
`;
export const HeroIMG = styled.img`
  height: auto;
  width: 80%;
  max-width: 90em;
  padding-bottom: 1.5em;

  @media (min-width: 56em) {
    width: 100%;
    height: auto;
  }
  @media (min-width: 68em) {
    width: 50%;
    height: auto;
  }
`;

export const RightSplitDiv = styled.div`
  width: 100%;
  height: 100%;
  /* border: 4px solid yellow; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-basis: 100%;
  max-height: 50%;

  @media (min-width: 48em) {
    padding-right: 1em;
  }
`;

export const SectionTitle = styled.h2`
  font-family: pirulen;
  display: flex;
  color: white;
  padding-bottom: 0.5em;
  line-height: 1;
  font-size: 2rem;
  text-align: center;

  @media (min-width: 47.5em) {
    font-size: 3rem;
  }
  @media (min-width: 68em) {
    font-size: 4.5rem;
  }
`;

export const InfoCarousel = styled.div`
  max-width: 80%;
  /* outline: 3px solid white; */
  /* background-color: rgba(0, 0, 0, 0.5); */
  color: white;
  font-family: roboto;
  font-size: 0.8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const SlideTitle = styled.h3`
  max-width: 50ch;
  line-height: 1;
  padding-bottom: 1em;

  @media (min-width: 47.5em) {
    font-size: 1.5rem;
  }
  @media (min-width: 68em) {
    font-size: 2.2rem;
  }
`;
export const HeroSubTitle = styled.h3`
  max-width: 50ch;
  line-height: 1;
  padding-bottom: 1em;
  font-size: 1.5rem;

  @media (min-width: 47.5em) {
    font-size: 2rem;
  }
  @media (min-width: 68em) {
    font-size: 2.6rem;
  }
`;

export const SlideText = styled.p`
  font-size: 0.77rem;
  max-width: 70ch;
  @media (orientation: landscape) {
    font-size: 0.7rem;
  }
  @media (min-width: 47.5em) {
    font-size: 0.9rem;
  }

  @media (min-width: 56em) {
    font-size: 1.2rem;
  }
  @media (min-width: 68em) {
    font-size: 1.2rem;
  }
`;

export const ExternalLink = styled.a`
  color: #5baddc;
  text-decoration: none;
`;
