import { createContext, useState } from 'react'
import useProvideAuth from '../Hooks/useProvideAuth'

const AuthContext = createContext({})

export const ProvideAuth = function ProvideAuth({ children }) {
  const auth = useProvideAuth()

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
}

export default AuthContext
