import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import axios from 'axios'

import { useForm } from 'react-hook-form'

import { toast } from 'react-toastify'

import { useNavigate } from 'react-router-dom'

// Homepage Nav Components

//import NavBarSignup from "./NavElements/NavBarSignup";
//import NavMenu from "./NavElements/NavMenu";

import {
  SignUpPageWrapper,
  SignUpFormWrapper,
  Heading,
  FormContainer,
  InputContainer,
  Required,
  Input,
  ErrorMsg,
  Button,
  GeneralText,
  A,
  OrSymbolContainer,
  FancyDivider,
  GoogleSignInButton,
  GoogleIcon,
  ContinueWithGoogle,
  StyledLink,
} from '../UsersElements'

import Navbar from '../../../components/Headers/Navbar'
import MobileNavMenu from '../../../components/Headers/MobileNavMenu'
import Footer from '../../../components/Footer/Footer'

const { REACT_APP_MAIN_API_URL } = process.env

const SignUp = () => {
  const [isOpen, setIsOpen] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  // Handle component unmount
  useEffect(() => {
    setIsOpen(isOpen)
    return () => {
      setIsOpen(false)
    }
  }, [])

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  let history = useNavigate()

  const onSubmit = (data) => {
    axios
      .post(REACT_APP_MAIN_API_URL + '/auth/register', data)
      .then(function (response) {
        return axios
          .post(REACT_APP_MAIN_API_URL + '/auth/request-verify-token', {
            email: data.email,
          })
          .then(function (response) {
            toast.success(
              'Registration successful. You will be redirected to the home page, a new tab will open with the Experimenter beta registration form. Please allow pop ups from SilicoLabs if your browser is set to block them by default.',
              {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            )

            setTimeout(function () {
              history.push('/')
              window.open('https://forms.gle/48o7EkMDBXvc8b6r8')
            }, 5000)
          })
      })
      .catch(function (error) {
        if (
          error.response != undefined &&
          error.response.data.detail === 'REGISTER_USER_ALREADY_EXISTS'
        ) {
          toast.error('A user with the email already exists!', {
            position: 'top-right',
            autoClose: 7000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        } else {
          toast.error('There was an error during registration. ' + error, {
            position: 'top-right',
            autoClose: 7000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
  }

  return (
    <>
      <Navbar toggle={toggle} />
      <MobileNavMenu isOpen={isOpen} toggle={toggle} />
      <SignUpPageWrapper>
        <SignUpFormWrapper>
          <Heading>
            Create an account to receive updates about our software and register
            for beta testing
          </Heading>
          <FormContainer>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div>
                <InputContainer>
                  <label htmlFor="name">
                    Name <Required>*</Required>
                  </label>
                  <Input
                    id="name"
                    type="text"
                    placeholder="Enter your name"
                    name="name"
                    {...register('name', {
                      required: 'Your name is required.',
                    })}
                  />
                  {errors.name && (
                    <ErrorMsg className="errorMsg">
                      {errors.name.message}
                    </ErrorMsg>
                  )}
                </InputContainer>
              </div>
              <div>
                <InputContainer>
                  <label htmlFor="email">
                    Email <Required>*</Required>
                  </label>
                  <Input
                    id="email"
                    type="email"
                    placeholder="Enter your email address"
                    {...register('email', {
                      required: 'Email is required.',
                      pattern: {
                        value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                        message: 'Email is not valid.',
                      },
                    })}
                  />
                  {errors.email && (
                    <ErrorMsg className="errorMsg">
                      {errors.email.message}
                    </ErrorMsg>
                  )}
                </InputContainer>
              </div>
              <div>
                <InputContainer>
                  <label htmlFor="password">
                    Password <Required>*</Required>
                  </label>
                  <Input
                    id="password"
                    type="password"
                    placeholder="Enter your password"
                    {...register('password', {
                      required: 'Password is required.',
                      minLength: {
                        value: 6,
                        message: 'Password should be at-least 6 characters.',
                      },
                    })}
                  />
                  {errors.password && (
                    <ErrorMsg className="errorMsg">
                      {errors.password.message}
                    </ErrorMsg>
                  )}
                </InputContainer>
              </div>
              <div>
                <Button type="submit">Sign up</Button>
              </div>
              {/* <GeneralText>
                By creating an account, you agree to the{" "}
                <A href="#0">terms & conditions</A>, and our{" "}
                <A href="#0">privacy policy</A>.
              </GeneralText> */}
            </form>
            {/* <OrSymbolContainer>
                <FancyDivider aria-hidden="true"></FancyDivider>
                <div>Or</div>
                <FancyDivider aria-hidden="true"></FancyDivider>
              </OrSymbolContainer>
              <form>
                <div>
                  <GoogleSignInButton>
                    <GoogleIcon
                      viewBox="0 0 16 16"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M7.9 7v2.4H12c-.2 1-1.2 3-4 3-2.4 0-4.3-2-4.3-4.4 0-2.4 2-4.4 4.3-4.4 1.4 0 2.3.6 2.8 1.1l1.9-1.8C11.5 1.7 9.9 1 8 1 4.1 1 1 4.1 1 8s3.1 7 7 7c4 0 6.7-2.8 6.7-6.8 0-.5 0-.8-.1-1.2H7.9z" />
                    </GoogleIcon>
                    <ContinueWithGoogle>Continue with Google</ContinueWithGoogle>
                  </GoogleSignInButton>
                </div>
              </form> */}
            <GeneralText>
              Already have an account?{' '}
              <StyledLink to="/signin">Sign in</StyledLink>
            </GeneralText>
          </FormContainer>
        </SignUpFormWrapper>
        <Footer />
      </SignUpPageWrapper>
    </>
  )
}

export default SignUp
