import React, { useState, useEffect } from 'react'

import { useNavigate, useSearchParams } from 'react-router-dom'

import {
  SignUpPageWrapper,
  SignUpFormWrapper,
  Heading,
  InputContainer,
  FormContainer,
  Required,
  ErrorMsg,
  GenericButton,
  Input,
} from './UsersElements'

import Navbar from '../../components/Headers/Navbar'
import MobileNavMenu from '../../components/Headers/MobileNavMenu'
import Footer from '../../components/Footer/Footer'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

const { REACT_APP_MAIN_API_URL } = process.env

const ResetPassword = ({ setToken }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isVerified, setIsVerified] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm()

  useEffect(() => {
    setIsOpen(isOpen)
    return () => {
      setIsOpen(false)
    }
  }, [])

  let navigate = useNavigate()

  let resetPassword = (data) => {
    let resetToken = searchParams.get('token')

    axios
      .post(REACT_APP_MAIN_API_URL + '/auth/reset-password', {
        token: resetToken,
        password: data.password,
      })
      .then(function (response) {
        toast.success('Password reset successful!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })

        navigate('/', { replace: true })
      })
      .catch(function (error) {
        console.log(error)

        let errorText =
          'There was an issue with password reset. Please try again, or contact support@silicolabs.ca'

        if (error.response.data.detail === 'RESET_PASSWORD_BAD_TOKEN') {
          errorText =
            'The reset token has expired. Please request another reset email'
        }

        toast.error(errorText, {
          position: 'top-right',
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      })
  }

  return (
    <>
      <Navbar toggle={toggle} />
      <MobileNavMenu isOpen={isOpen} toggle={toggle} />
      <SignUpPageWrapper>
        <SignUpFormWrapper>
          <Heading>Choose your new password</Heading>

          <FormContainer>
            <form onSubmit={handleSubmit(resetPassword)}>
              <div></div>
              <div>
                <InputContainer>
                  <label htmlFor="password">
                    Password <Required>*</Required>
                  </label>
                  <Input
                    id="password"
                    type="password"
                    placeholder="Enter your new password"
                    {...register('password', {
                      required: 'Password is required.',
                      minLength: {
                        value: 6,
                        message: 'Password should be at-least 6 characters.',
                      },
                    })}
                  />
                  <Input
                    id="confirm_password"
                    type="password"
                    placeholder="Confirm your new password"
                    {...register('confirm_password', {
                      validate: (val) => {
                        if (watch('password') != val) {
                          return 'Your passwords do not match!'
                        }
                      },
                    })}
                  />
                  {errors.password && (
                    <ErrorMsg className="errorMsg">
                      {errors.password.message}
                    </ErrorMsg>
                  )}
                  {errors.confirm_password && (
                    <ErrorMsg className="errorMsg">
                      {errors.confirm_password.message}
                    </ErrorMsg>
                  )}
                </InputContainer>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <GenericButton style={{ width: '95%' }} type="submit">
                  Reset Password
                </GenericButton>
              </div>
            </form>
          </FormContainer>
        </SignUpFormWrapper>
        <Footer />
      </SignUpPageWrapper>
    </>
  )
}

export default ResetPassword
