import styled from "styled-components";

export const Body = styled.div`
  margin: 0;
  box-sizing: border-box;
  background-image: url(Background.png);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const PageContent = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 6rem;
`;

export const H1 = styled.h1`
  color: white;
  font-size: 2.5rem;
  max-width: 45ch;
  padding: 1em;

  @media (min-width: 48em) {
    font-size: 4rem;
  }
`;
export const P = styled.p`
  color: white;
  font-size: 1.2rem;
  max-width: 80%;
  padding-bottom: 1em;

  @media (min-width: 48em) {
    font-size: 2rem;
  }
  @media (min-width: 56em) {
    font-size: 2.5rem;
    max-width: 45ch;
  }
`;

export const A = styled.a`
  color: white;
  font-size: 1.2rem;
  max-width: 80%;
  padding-bottom: 1em;

  @media (min-width: 48em) {
    font-size: 2rem;
  }
  @media (min-width: 56em) {
    font-size: 2.5rem;
    max-width: 45ch;
  }
`;
