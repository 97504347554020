// *** ONLY FOR SUPERUSERS*** A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated. One authenticated, the

import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useAuth } from '../Hooks/useAuth'

// user will be taken to the original page they were trying to access
const SuperUserRoute = function () {
  let auth = useAuth()
  let location = useLocation()

  return auth.IsSuperAuthorized() ? (
    <Outlet />
  ) : (
    <Navigate to="/signin" state={{ from: location }} replace />
  )
}

export default SuperUserRoute
