import React, { useState, useEffect } from 'react'

import { Link, useSearchParams } from 'react-router-dom'

import { SignUpPageWrapper, SignUpFormWrapper, Heading } from './UsersElements'

import Navbar from '../../components/Headers/Navbar'
import MobileNavMenu from '../../components/Headers/MobileNavMenu'
import Footer from '../../components/Footer/Footer'
import axios from 'axios'

const { REACT_APP_MAIN_API_URL } = process.env

const Verify = ({ setToken }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isVerified, setIsVerified] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  // Handle component unmount
  useEffect(() => {
    console.log('Verify Page is loaded!')
    let verifyToken = searchParams.get('token')

    axios
      .post(REACT_APP_MAIN_API_URL + '/auth/verify', { token: verifyToken })
      .then(function (response) {
        console.log('Verify successful!')
        console.log(response)
        localStorage.setItem('isVerified', JSON.stringify(true))
        setIsVerified(true)
      })
      .catch(function (error) {
        if (error.response.data.detail === 'VERIFY_USER_ALREADY_VERIFIED') {
          setIsVerified(true)
        } else {
          console.log('Verify failed!')
          console.log(error)
          setIsVerified(false)
        }
      })

    setIsOpen(isOpen)
    return () => {
      setIsOpen(false)
    }
  }, [])

  return (
    <>
      <Navbar toggle={toggle} />
      <MobileNavMenu isOpen={isOpen} toggle={toggle} />
      <SignUpPageWrapper>
        <SignUpFormWrapper>
          {!isVerified && (
            <Heading>Please wait while you are verified...</Heading>
          )}
          {isVerified && (
            <Heading>
              Thanks for verifying your email! Check out your{' '}
              <Link to="/profile">profile here.</Link> (requires sign in)
            </Heading>
          )}
        </SignUpFormWrapper>
        <Footer />
      </SignUpPageWrapper>
    </>
  )
}

export default Verify
