export function DownloadFile(fileData, responseHeaders) {
  const url = window.URL.createObjectURL(new Blob([fileData]))
  const link = document.createElement('a')
  link.href = url

  // Grab the filename from the header
  let headerString = responseHeaders['content-disposition']
  headerString = headerString.replace(/filename\*=utf-8\'\'/g, 'filename=')
  headerString = headerString.replace(/%20/g, ' ')
  let fileName = headerString.split('filename=')[1]
  fileName = fileName.replace(/\"/g, '')

  // Set the download attr to the filename so the browser triggers a download
  link.setAttribute('download', fileName)
  // Start download
  link.click()

  // Clean up and remove the link
  window.URL.revokeObjectURL(url)
}
