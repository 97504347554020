import axios from 'axios'
import qs from 'qs'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

const {
  REACT_APP_MAIN_API_URL,
  REACT_APP_MAIN_API_USERNAME,
  REACT_APP_MAIN_API_PASSWORD,
} = process.env

// Clears a token from localstorage
function clearToken() {
  localStorage.removeItem('expiringToken')
}

// Removes currentUser from localstorage
function clearCurrentUser() {
  localStorage.removeItem('currentUserId')
}

// Gets a token if it's still valid (not expired), otherwise
// returns null
function getToken() {
  var token = localStorage.getItem('expiringToken')

  if (token == null) {
    return null
  }

  token = JSON.parse(token)

  if (token.expiry < new Date().getTime()) {
    clearCurrentUser()
    clearBetaAuthorized()
    return null
  }

  return token.value
}

// TODO: refactor these setter/getters. There is much duplication here.
function setSuperUser(isSuperUser) {
  localStorage.setItem('isSuperUser', JSON.stringify(isSuperUser))
}

function setBetaAuthorized(isBetaAuthorized) {
  localStorage.setItem('isBetaAuthorized', JSON.stringify(isBetaAuthorized))
}

function setIsVerified(isVerified) {
  localStorage.setItem('isVerified', JSON.stringify(isVerified))
}

function clearBetaAuthorized() {
  localStorage.removeItem('isBetaAuthorized')
}

function IsBetaAuthorized() {
  var isBetaAuthorized = localStorage.getItem('isBetaAuthorized')

  if (isBetaAuthorized == null) {
    return false
  }

  isBetaAuthorized = JSON.parse(isBetaAuthorized)

  return isBetaAuthorized
}

function IsVerifiedCheck() {
  var isVerified = localStorage.getItem('isVerified')

  if (isVerified == null) {
    return false
  }

  isVerified = JSON.parse(isVerified)

  return isVerified
}

function getSuperUser() {
  var isSuperUser = localStorage.getItem('isSuperUser')

  if (isSuperUser == null) {
    return false
  }

  isSuperUser = JSON.parse(isSuperUser)

  return isSuperUser
}

// Saves a token with a ttl in ms
function saveTokenWithExpiry(token, ttl) {
  var now = new Date()

  var tokenItem = {
    value: token,
    expiry: now.getTime() + ttl,
  }

  localStorage.setItem('expiringToken', JSON.stringify(tokenItem))
}

// Saves the current user name so we can log it later on
function saveCurrentUser(username) {
  clearCurrentUser()

  var userItem = {
    value: username,
  }

  localStorage.setItem('currentUserId', JSON.stringify(userItem))
}

function getCurrentUser() {
  var username = localStorage.getItem('currentUserId')

  if (username == null) {
    return null
  }

  username = JSON.parse(username)
  return username.value
}

const useProvideAuth = function () {
  const navigate = useNavigate()

  const signin = (username, password, from) => {
    return axios
      .post(
        REACT_APP_MAIN_API_URL + '/auth/jwt/login',
        qs.stringify({ username: username, password: password })
      )
      .then(function (response) {
        saveTokenWithExpiry(response.data.access_token, 1000 * 60 * 10)

        return axios
          .get(REACT_APP_MAIN_API_URL + '/users/me')
          .then(function (response) {
            setBetaAuthorized(response.data.is_beta_authorized)
            setIsVerified(response.data.is_verified)
            setSuperUser(response.data.is_superuser)
            saveCurrentUser(response.data.id)
            navigate(from, { replace: true })
            return response.data.access_token
          })
      })
      .catch(function (error) {
        if (
          error.response != undefined &&
          error.response.data.detail === 'LOGIN_BAD_CREDENTIALS'
        ) {
          toast.error('Sign in failed. Incorrect username or password.', {
            position: 'top-right',
            autoClose: 7000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        } else {
          toast.error('There was an error during sign in. ' + error, {
            position: 'top-right',
            autoClose: 7000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
  }

  const IsVerified = () => {
    return IsVerifiedCheck()
  }

  const IsAuthorized = () => {
    var token = getToken()
    // Token has not expired
    return token != null && IsBetaAuthorized()
  }

  const IsSuperAuthorized = () => {
    return IsAuthorized() && getSuperUser()
  }

  const signout = (cb) => {
    clearToken()
    clearCurrentUser()
    clearBetaAuthorized()
    setSuperUser(false)
    cb()
  }

  return {
    signin,
    IsVerified,
    IsAuthorized,
    IsSuperAuthorized,
    signout,
  }
}

export { getToken, getCurrentUser }
export default useProvideAuth
