import React, { useState, useEffect } from 'react'

import { Link, useLocation, useSearchParams } from 'react-router-dom'

import {
  SignUpPageWrapper,
  SignUpFormWrapper,
  Heading,
  Button,
  GenericButton,
} from './UsersElements'

import Navbar from '../../components/Headers/Navbar'
import MobileNavMenu from '../../components/Headers/MobileNavMenu'
import Footer from '../../components/Footer/Footer'
import axios from 'axios'

const { REACT_APP_MAIN_API_URL } = process.env

const RequestVerify = ({ setToken }) => {
  const [isOpen, setIsOpen] = useState(false)

  const location = useLocation()

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  // Handle component unmount
  useEffect(() => {
    setIsOpen(isOpen)
    return () => {
      setIsOpen(false)
    }
  }, [])

  const requestVerify = () => {
    axios
      .get(REACT_APP_MAIN_API_URL + '/users/me')
      .then((response) => {
        if (response.status === 200) {
          return axios.post(
            REACT_APP_MAIN_API_URL + '/auth/request-verify-token',
            { email: response.data.email }
          )
        }
      })
      .catch((error) => {
        console.log('There was an issue requesting a verification link')
        console.log(error)
      })
  }

  // TODO: Fix this styling and linking

  return (
    <>
      <Navbar toggle={toggle} />
      <MobileNavMenu isOpen={isOpen} toggle={toggle} />
      <SignUpPageWrapper>
        <SignUpFormWrapper>
          <Heading>
            Oops, looks you're not verified yet! Click below to resend a
            verification email
          </Heading>

          <GenericButton type="reset" onClick={requestVerify}>
            Send Verification Email
          </GenericButton>
          <br />
          <br />
          <Link style={{ paddingTop: 10 }} to="/profile">
            Or, Go back to experiment data
          </Link>
        </SignUpFormWrapper>
        <Footer />
      </SignUpPageWrapper>
    </>
  )
}

export default RequestVerify
