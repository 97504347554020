import React from "react";
import { FaTimes } from "react-icons/fa";
import {
  CloseIconContainer,
  MobileNavMenuContainer,
  MenuItems,
  NavbarLogo,
  NavbarItems,
} from "./MobileNavMenuStyels";
import { useLocation } from "react-router-dom";
import { NavItems, Logos } from "./NavbarItems";
import { Link as LinkR } from "react-router-dom";
import AuthButtons from "../../pages/Users/AuthButtons";

function GetLinksMatchingCurrentPage() {
  const PageName =
    useLocation().pathname.replace("/", "") + "NavItem".toString();

  return NavItems.filter((l) => {
    return l.key.includes(PageName);
  });
}
function GetLogoMatchingCurrentPage() {
  const PageName =
    useLocation().pathname.replace("/", "") + "NavLogo".toString();

  return Logos.filter((l) => {
    return l.key.includes(PageName);
  });
}

const MobileNavMenu = ({ isOpen, toggle }) => {
  const currentPageLinks = GetLinksMatchingCurrentPage();
  const currentPageLogo = GetLogoMatchingCurrentPage();
  return (
    <MobileNavMenuContainer isOpen={isOpen} onClick={toggle}>
      <CloseIconContainer onClick={toggle}>
        <FaTimes />
      </CloseIconContainer>
      <MenuItems>
        {" "}
        <NavbarLogo>
          {currentPageLogo.map((item, index) => {
            return (
              <li className="LogoContainer" key={index}>
                <img className="nav-logo" src={item.src} alt={item.alt} />
              </li>
            );
          })}
        </NavbarLogo>{" "}
        <NavbarItems>
          {currentPageLinks.map((item, index) => {
            return (
              <li key={index}>
                <LinkR className={item.cName} id="MobileNavLinks" to={item.url}>
                  {item.title}
                </LinkR>
              </li>
            );
          })}
        </NavbarItems>{" "}
        <AuthButtons />
      </MenuItems>
    </MobileNavMenuContainer>
  );
};

export default MobileNavMenu;
