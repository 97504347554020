export const NavItems = [
  {
    title: "Home",
    url: "/",
    key: [
      "experimenterNavItem",
      "contactusNavItem",
      "downloadNavItem",
      "signinNavItem",
      "signupNavItem",
      "usermanualNavItem",
      "usermanual/designframeworkNavItem",
      "usermanual/blocksNavItem",
      "usermanual/environmentNavItem",
      "usermanual/sessionsNavItem",
      "usermanual/trialsNavItem",
      "usermanual/screensNavItem",
      "usermanual/responsiveobjectsNavItem",
      "usermanual/scenescapesNavItem",
      "usermanual/propertiesNavItem",
      "usermanual/visualperspectivesNavItem",
      "usermanual/inputresponsemapsNavItem",
      "usermanual/machinelearningNavItem",
      "usermanual/downloadinstallNavItem",
      "usermanual/userinterfaceNavItem",
    ],

    cName: "nav-link",
  },
  {
    title: "Contact",
    url: "/contactus",
    key: [
      "NavItem",
      "experimenterNavItem",
      "downloadNavItem",
      "signinNavItem",
      "signupNavItem",
      "usermanualNavItem",
      "usermanual/designframeworkNavItem",
      "usermanual/blocksNavItem",
      "usermanual/environmentNavItem",
      "usermanual/sessionsNavItem",
      "usermanual/trialsNavItem",
      "usermanual/screensNavItem",
      "usermanual/responsiveobjectsNavItem",
      "usermanual/scenescapesNavItem",
      "usermanual/propertiesNavItem",
      "usermanual/visualperspectivesNavItem",
      "usermanual/inputresponsemapsNavItem",
      "usermanual/machinelearningNavItem",
      "usermanual/downloadinstallNavItem",
      "usermanual/userinterfaceNavItem",
    ],
    cName: "nav-link",
  },
  {
    title: "Experimenter",
    url: "/experimenter",
    key: [
      "NavItem",
      "contactusNavItem",
      "downloadNavItem",
      "usermanualNavItem",
      "usermanual/designframeworkNavItem",
      "usermanual/blocksNavItem",
      "usermanual/environmentNavItem",
      "usermanual/sessionsNavItem",
      "usermanual/trialsNavItem",
      "usermanual/screensNavItem",
      "usermanual/responsiveobjectsNavItem",
      "usermanual/scenescapesNavItem",
      "usermanual/propertiesNavItem",
      "usermanual/visualperspectivesNavItem",
      "usermanual/inputresponsemapsNavItem",
      "usermanual/machinelearningNavItem",
      "usermanual/downloadinstallNavItem",
      "usermanual/userinterfaceNavItem",
    ],
    cName: "nav-link",
  },
  {
    title: "Downloads",
    url: "/download",
    key: [
      "NavItem",
      "experimenterNavItem",
      "contactusNavItem",
      "signinNavItem",
      "signupNavItem",
      "usermanualNavItem",
      "usermanual/designframeworkNavItem",
      "usermanual/blocksNavItem",
      "usermanual/environmentNavItem",
      "usermanual/sessionsNavItem",
      "usermanual/trialsNavItem",
      "usermanual/screensNavItem",
      "usermanual/responsiveobjectsNavItem",
      "usermanual/scenescapesNavItem",
      "usermanual/propertiesNavItem",
      "usermanual/visualperspectivesNavItem",
      "usermanual/inputresponsemapsNavItem",
      "usermanual/machinelearningNavItem",
      "usermanual/downloadinstallNavItem",
      "usermanual/userinterfaceNavItem",
    ],
    cName: "nav-link",
  },
  {
    title: "User Manual",
    url: "/usermanual",
    key: ["experimenterNavItem"],
    cName: "nav-link",
  },
];

export const Logos = [
  {
    title: "SilicoLabs With Logo",
    src: "images/HeaderLogos/SilicoLabs_WithLogo.png",
    alt: "Stylized lettering that reads: 'SilicoLabs -Experimenter'",
    key: [
      "NavLogo",
      "contactusNavLogo",
      "downloadNavLogo",
      "signinNavLogo",
      "signupNavLogo",
    ],
    url: "/",
    cName: "nav-logo",
  },
  {
    title: "SilicoLabs Experimenter With Logo",
    src: "/images/HeaderLogos/SilicoLabs_Experimenter_WithLogo.png",
    alt: "Stylized lettering that reads: 'SilicoLabs -Experimenter'",
    key: [
      "experimenterNavLogo",
      "usermanualNavLogo",
      "usermanual/designframeworkNavLogo",
      "usermanual/blocksNavLogo",
      "usermanual/environmentNavLogo",
      "usermanual/sessionsNavLogo",
      "usermanual/trialsNavLogo",
      "usermanual/screensNavLogo",
      "usermanual/responsiveobjectsNavLogo",
      "usermanual/scenescapesNavLogo",
      "usermanual/propertiesNavLogo",
      "usermanual/visualperspectivesNavLogo",
      "usermanual/inputresponsemapsNavLogo",
      "usermanual/machinelearningNavLogo",
      "usermanual/downloadinstallNavLogo",
      "usermanual/userinterfaceNavLogo",
    ],
    url: "/experimenter",
    cName: "nav-logo",
  },
  {
    title: "SilicoLabs Experimenter Without Logo",
    src: "/images/HeaderLogos/SilicoLabs_Graphic.png",
    alt: "Stylized lettering that reads: 'SilicoLabs -Experimenter'",
    key: [""],
    url: "/experimenter",
    cName: "nav-logo",
  },
  {
    title: "SilicoLabs Without Logo",
    src: "/images/HeaderLogos/SilicoLabs_WithoutLogo.png",
    alt: "Stylized lettering that reads: 'SilicoLabs'",
    key: [""],
    url: "/",
    cName: "nav-logo",
  },
  {
    title: "Participant",
    src: "/images/HeaderLogos/PARTICIPANTTEMP.png",
    alt: "Stylized lettering that reads: 'Participant'",
    key: [""],
    cName: "nav-logo",
  },
];
