import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const SignUpPageWrapper = styled.section`
  @media screen and (max-width: 768px) {
    overflow-y: scroll;
    overflow-x: hidden;
  }

  @media screen and (max-width: 480px) {
    overflow-y: scroll;
    overflow-x: hidden;
  }

  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

export const SignUpFormWrapper = styled.section`
  color: #ffffff;
  padding-bottom: 3em;
  padding-top: 3em;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 768px) {
    padding-top: 6rem;
  }

  @media screen and (max-width: 480px) {
    padding-top: 3.5rem;
  }
`

export const Heading = styled.h1`
  padding: 1.3em;
  padding-top: 3em;
  max-width: 45ch;
  text-align: center;
  font-size: 1.8rem;
`

export const FormContainer = styled.div`
  width: 90%;

  max-width: 24rem;
`

export const InputContainer = styled.div`
  margin-bottom: 5px;
`

export const Input = styled.input`
  /* Full-width inputs */
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
  &:select.textarea {
    color: #ffffff;
  }
`

export const Required = styled.span`
  color: #ea1560;
`

export const ErrorMsg = styled.p`
  color: #ea1560;
  font-size: small;
`

/* Set a style for all buttons */
export const Button = styled.button`
  background-color: #04aa6d;
  color: white;
  padding: 14px 20px;
  margin: 12px 0;
  border: none;
  cursor: pointer;
  width: 100%;

  &:hover {
    opacity: 0.8;
  }
`

export const GenericButton = styled.button`
  background-color: #9a9a9a;
  color: white;
  padding: 14px 20px;
  margin: 12px 0;
  border: none;
  cursor: pointer;
  width: auto;
  font-size: 1em;
  align: center;

  &:hover {
    opacity: 0.8;
  }
`

export const GoogleSignInButton = styled.button`
  display: flex;
  background-color: red;
  color: white;
  padding: 14px 0px;
  align-items: center;
  margin: 12px 0;
  border: none;
  cursor: pointer;
  width: 100%;
  &:hover {
    opacity: 0.8;
  }
`

export const GoogleIcon = styled.svg`
  display: block;
  color: white;
  opacity: 0.75;
  fill: currentColor;
  width: 1rem;
  height: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  flex-shrink: 0;
`

export const ContinueWithGoogle = styled.span`
  flex: 1 1 auto;
  margin-left: -4rem;
  padding-left: 4rem;
  padding-right: 2rem;
`

export const OrSymbolContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
`

export const FancyDivider = styled.div`
  flex-grow: 1;
  margin-right: 0.75rem;
  margin-left: 0.75rem;
  border: 0 solid #eaeaea;
  border-color: white;
  border-top-width: 1px;
`

export const A = styled.a`
  color: #b3d4ff;
`

export const StyledLink = styled(Link)`
  color: #b3d4ff;
`

export const GeneralText = styled.div`
  text-align: center;
`

export const Body = styled.div`
  margin: 0;
  box-sizing: border-box;
`

export const DownloadBody = styled.body`
  scroll-snap-align: start;
  margin: 0;
  box-sizing: border-box;
  background: #222222;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  padding-top: 4rem;
`

export const Title = styled.h1`
  color: white;
  font-size: 3em;
  padding: 3rem;
`

export const Section = styled.section`
  display: flex;
  flex-wrap: wrap;
  max-width: 80%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  padding-left: 2em;
  padding-bottom: 4rem;
`
export const Card = styled.div`
  height: 20em;
  border-radius: 15%;
  width: 20em;
  margin: 1em 1em 0.5em 1em;
  background-color: lightslategray;
  filter: grayscale(50%);
  transition: 2s;

  &:hover {
    filter: none;
    cursor: pointer;
    background-color: white;
    transform: scale(1.05);
  }
`

export const SectionTitle = styled.h2`
  color: white;
  align-self: flex-start;
  font-size: 2.5rem;
  padding: 0.5rem;
`

export const SectionContents = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
`

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const CardTitle = styled.h3`
  text-align: center;
  max-width: 80%;
  color: white;
  font-size: 2rem;
  max-width: 15ch;
  padding-bottom: 0.75em;
  padding-top: 0.75em;
`

export const DownloadCardImage = styled.img`
  max-width: 100%;
  height: auto;
`
